import React, { Component } from "react";
import Helmet from "react-helmet";

import HomeUnAuthenticated from "../components/HomeUnAuthenticated";
import HomeAuthenticated from "../components/HomeAuthenticated";

class Home extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Home</title>
          <meta name="description" content="" />
        </Helmet>
        {this.props.isAuthenticated ? (
          <HomeAuthenticated />
        ) : (
          <HomeUnAuthenticated />
        )}
      </div>
    );
  }
}

export default Home;
