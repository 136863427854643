import React from "react";
import Helmet from "react-helmet";
import { Row, Col, Grid } from "react-bootstrap";

import BannerTextured from "../components/wrappers/BannerTextured";
import SubHeading from "../components/wrappers/SubHeading";
import DoubleEntendreTable from "../components/DoubleEntendreTable";

const styles = {
  subHeading2: {
    fontWeight: "bold",
    paddingBottom: "20px",
    // fontFamily: 'Verdana, Verdana, Geneva, sans-serif'
    fontFamily: "Arial, Arial, Helvetica, sans-serif"
  },
  intro: {
    // fontFamily: 'Verdana, Verdana, Geneva, sans-serif',
    fontFamily: "Arial, Arial, Helvetica, sans-serif",
    textAlign: "left ",
    paddingLeft: "15px",
    paddingRight: "15px"
  }
};

const DoubleEntendres = () => (
  <div>
    <Helmet>
      <title>Double Entendres</title>
      <meta name="description" content="" />
    </Helmet>

    {/*Page Banner*/}
    <BannerTextured>
      <div className="container">
        <Row>
          <Col xs={12}>
            <SubHeading>Double Entendres</SubHeading>
            <h4 style={styles.subHeading2}> In chronological order</h4>

            <h4 style={styles.intro}>
              All double entendres are a natural part of the fictional
              narrative, thus posing zero hindrance for non-fans (accommodating
              a larger audience) while providing a double pay off for fans in
              the know (core audience). Furthermore, Stones nuance, tastefully
              imbued and by no means saturated, is multi-layered, thus pleasing
              to both aficionados and novice fans.
            </h4>
          </Col>
        </Row>
      </div>
    </BannerTextured>
    <Grid className="container">
      <Col xs={12}>
        <Row>
          <DoubleEntendreTable />
        </Row>
      </Col>
    </Grid>
  </div>
);

export default DoubleEntendres;
