import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

// All the following keys are optional.
// We try our best to provide a great default value.
const Theme = createMuiTheme({
  overrides: {
    MuiCollapse: {
      entered: {
        height: 'auto',
        overflow: 'visible',
      },
    },
    MuiInputLabel: {
      root: {
        color: '#449B44',
        fontSize: 32,
      },
    },
    MuiFormLabel: {
      root: {
        color: 'green',
        fontSize: 32,
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        minHeight: 100,
      },
      content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        backgroundColor: '#D3D3D3',
      },
    },
  },
  palette: {
    primary: {
      light: '#757ce8',
      main: '#36AAD1',
      dark: '#36AAD1',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#449B44',
      dark: '#449B44',
      contrastText: 'yellow',
    },
    alternate: {
      light: '#fff',
      main: '#E7E247',
      dark: '#fff',
      contrastText: 'yellow',
    },
    text: {
      primary: '#449B44',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
    background: {
      paper: '#fff',
      default: '#f1f1f1',
    },
  },

  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
  },
});

export default Theme;
