// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:e9894abe-379f-4161-8cef-bcc709d65bea",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_yWrErsmIw",
    "aws_user_pools_web_client_id": "2i3ff5gnerff30haa56qcimcp2",
    "oauth": {}
};


export default awsmobile;
