import React from 'react';
import Helmet from 'react-helmet';
import { Grid, Row, Col } from 'react-bootstrap';
import BannerTextured from '../components/wrappers/BannerTextured';
import SubHeading from '../components/wrappers/SubHeading';
import ParagraphS1 from '../components/wrappers/ParagraphS1';

// storyboards
import StoryBoardsCarousel from '../components/StoryBoardsCarousel';

const styles = {
  label: {
    fontSize: '12px',
    color: '#2F2F2F',
    letterSpacing: '.5px',
    fontFamily: 'Tahoma, Geneva, sans-serif',
    textDecoration: 'none',
    backgroundColor: '#E7E247',
    height: '20px',
  },
  highlight: {
    fontWeight: '900',
  },
  highlightBold: {
    fontWeight: '900',
    textDecoration: 'underline',
  },
  underLine: {
    textDecoration: 'underline',
  },
  storyBoards: {
    marginTop: '40px',
  },
};

const P1Synopsis = () => (
  <div className="no-user-select">
    <Helmet>
      <title>Part 1: Sunrise Synopsis</title>
      <meta name="description" content="" />
    </Helmet>

    <BannerTextured>
      <div className="container">
        <Row>
          <Col md={8} mdOffset={2}>
            <SubHeading>Part 1: Sunrise Synopsis</SubHeading>
          </Col>
        </Row>
      </div>
    </BannerTextured>

    <Grid>
      <Row>
        <Col md={12} mdOffset={0}>
          <ParagraphS1>
            <span style={styles.highlight}>Bill Wyman</span>
            {' '}
(though the actor shares the same age,
            physique, personality, etc., as the Stones’ bassist, his visage remains his own), one of
            five aging Dons of the infamous Crawdaddy Crew (the Outfit), blows a young punk’s brains
            out for wolf-whistling at his girl in a bar in Sofia, Bulgaria. Meanwhile, stateside,
            after being passed over for promotion
            {' '}
            <i>again</i>
, and dreams of emulating his deceased Pulitzer-winning parents unrealized,
            {' '}
            <span style={styles.highlight}>John(ny) Goodfellow Jr</span>
, (40, youthful) quits the
            tabloid desk at the Kansas City Star to start afresh out in California where he and his
            wife
            {' '}
            <span style={styles.highlight}>Babes</span>
            {' '}
(28, gorgeous) have bought a stake in her
            uncle’s farm vineyard.
          </ParagraphS1>

          <ParagraphS1>
            On arrival in the Valley,
            {' '}
            <span style={styles.highlight}>Uncle Eddie</span>
            {' '}
(50’s,
            inconspicuously gay) teaches Johnny how to prune the barren vines via
            {' '}
            <span style={styles.underLine}>big curved hand shears</span>
. In New York, ad interim,
            {' '}
            <span style={styles.highlight}>Mick Jagger</span>
, another Don, and his right-hand man
            {' '}
            <span style={styles.highlight}>Curtis Strangle</span>
            {' '}
(60’s, neck and hands of a bear)
            discuss Bill’s predicament. Later, via a call to
            {' '}
            <span style={styles.highlight}>Ron(nie) Wood</span>
, a fellow Don based in Thailand,
            Bill’s forthcoming jailbreak and plastic surgery are implied.
          </ParagraphS1>

          <ParagraphS1>
            Winter gives way to spring in wine country and the Goodfellows have settled in nicely
            until a parcel arrives that upends their world. Thinking it’s a hard drive left behind
            in Kansas, Johnny is shell-shocked to find a faded gold sports watch and letter
            informing him that his parents did not perish in a Marxist bombing in Caracas 35 years
            prior as stipulated in official US/Venezuelan records, but were in fact seen alive in
            Amazonas State one week after that incident. Call (#) if seek the truth. After flipping
            the watch and observing the engraving “Happy Birthday Dad, Love Johnny & Mom”, he
            shakily gulps his vino, grounds it harshly,
            {' '}
            <span style={styles.underLine}>spilling some on his left hand</span>
.
          </ParagraphS1>

          <ParagraphS1>
            At his East-London jazz club,
            {' '}
            <span style={styles.highlight}>Charlie Watts</span>
,
            another Don, and
            {' '}
            <span style={styles.highlight}>Pete Townsend</span>
, his loyal
            aide-de-camp, brief two gangsters
            {' '}
            <span style={styles.highlight}>Owen</span>
            {' '}
and
            {' '}
            <span style={styles.highlight}>Terry</span>
            {' '}
(30’s) on their mission to spring Bill out
            of jail. Meanwhile, in the Valley, after the watch’s authenticity is confirmed, Johnny
            makes the call and is instructed by an unidentified male to meet him in Caracas in 7
            days time
            {' '}
            <i>alone</i>
            {' '}
if he desires to find out what really happened to his parents.
          </ParagraphS1>

          <ParagraphS1>
            With the trauma of losing his folks, repressed via years of shrinks and cocaine abuse
            (declared via an earlier conversation), now bubbling to the surface along with a burning
            desire to seek the truth and cauterize these wounds, Johnny, against Babes’ wishes,
            absconds to Caracas where he meets
            {' '}
            <span style={styles.highlight}>Dominic Debreto </span>
            {' '}
(60’s, robust), Vietnam vet,
            ex-cop and PI out of New York. Debreto divulges that he was hired by Johnny’s folks,
            then reporters for the Times, in the mid-70’s to dig up dirt on Mick who was expanding
            the empire on the east coast to which they in turn penned damaging stories on the Outfit
            albeit under pseudonyms
            {' '}
            <i>this time</i>
. However, the Outfit found out, and incensed that the Goodfellows were
            {' '}
            <i>still</i>
            {' '}
needling them, put contracts out on their lives. Though aware of this fact,
            the Goodfellows flew to Caracas shortly after to follow the trial of
            {' '}
            <span style={styles.highlight}>Keith Richards</span>
, another Don and known voodoo
            practitioner, for a local murder in which it is implied that they were kidnapped,
            tortured and cannibalized at Keith’s jungle lair soon after his acquittal. After barely
            recovering from this chastening news, Johnny is aghast when Debreto reveals his plan to
            take out the South American arm of the Outfit along with offering him partnership and a
            chance of payback. Wanting no part of it, Johnny ponders his sanity.
          </ParagraphS1>

          {/* <Button
              variant="contained"
              color="alternate"
              style={styles.label}
              href="https://drive.google.com/file/d/1a7SU_cibHQyddyv2tRHWg1275OaIXCZg/view?usp=sharing"
              target="_blank"
              className="scriptExcerptButton"
            >
              View Script Excerpt
            </Button> */}

          <span className="custom-script-button">
            <a
              href="https://drive.google.com/file/d/1zGN9hunnD7kYd_BVwhvSl8XRYYwQ_Ird/view?usp=…"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="script-synopsis-button">View Script Excerpt</span>
            </a>
          </span>

          <ParagraphS1>
            In a semi-rural area just outside of Sofia, Bulgaria, Owen, Terry and two hired thugs
            lay in ambush for a police caravan escorting Bill to a regional court for trial. Shortly
            after the front and rear motorcycle escorts, who were in on it, peel off and abscond,
            SWOOSH-BANG-an RPG hits the front squad car. Seconds later, Terry and an accomplice
            LIGHT UP the occupants in the rear squad car followed by the guards up front in the
            prison van via AK47 fire. Bill is then loaded into a van driven by Owen and spirited
            away. Meanwhile, Pete, observing proceedings from afar, makes haste to a getaway
            speedboat moored under a bridge and waits. Once Owen and Co arrive and board, Pete helms
            the vessel down the Struma River en route to Serbia.
          </ParagraphS1>

          {/* <Button
              variant="contained"
              color="alternate"
              style={styles.label}
              href="https://drive.google.com/file/d/1qRqRUq9M5UxKBYVWScpxu365sRS35rac/view"
              target="_blank"
              className="scriptExcerptButton"
            >
              View Script Excerpt
            </Button> */}

          <span className="custom-script-button">
            <a
              href="https://drive.google.com/file/d/1Oa5i41bP8EENv0mSnxm0v1rFfmsxiSWP/view?usp=…"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="script-synopsis-button">View Script Excerpt</span>
            </a>
          </span>

          <ParagraphS1>
            Beset by intrigue, Johnny agrees to meet Debreto the next day. After a mafia city tour,
            they head to the track in hope of catching a glimpse of Keith who occasionally watches
            his thoroughbred’s race. No luck, but they do clock some of his acquaintances, including
            a hardened street kid named
            {' '}
            <span style={styles.highlight}>Mojo</span>
            {' '}
(10). Post track,
            Debreto reveals that his only son overdosed on Outfit cocaine two years earlier, hence
            his motivation to take out Richards, to which Johnny empathizes. Later that night,
            Johnny accompanies Debreto to his makeshift detective’s operation room at his house in
            the burbs where he is schooled on the history and current status of the Outfit (via
            authentic/doctored Rolling Stones photos, newspaper clippings, etc., that avert direct
            reference to ‘The Rolling Stones’), including the fact that it is their
            {' '}
            <span style={styles.highlight}>50th year of operation</span>
            {' '}
along with the reason for
            their exile from England in ’71, instigated by a newspaper headline story authored by
            John and Belinda Goodfellow that won them their Pulitzers and the enmity of the Outfit.
            Later over chow, Johnny agrees to join Debreto on a recon mission to Richards’ jungle
            lair - and crosses a threshold.
          </ParagraphS1>

          <ParagraphS1>
            Bill arrives at Ronnie’s Thailand residence and undergoes facial plastic surgery
            (results of remain obscure) prior to boarding a long-tail boat en route to Myanmar.
            Meanwhile, after procuring equipment and acclimatizing in the jungle a few days, Debreto
            and Johnny arrive at La Mision, a small remote outpost in northern Amazonas State, not
            far from Keith’s jungle lair where they meet Debreto’s associate
            {' '}
            <span style={styles.highlight}>Sopai</span>
            {' '}
(60’s), a full-blooded Yanonami Indian and
            ordained Catholic priest, in his rectory. After affirming that it was he who
            eye-witnessed Johnny’s parents’ demise in Keith’s camp in the mid-70’s, Sopai fetches a
            map outlining the route to the camp and offers to be their guide to which they accept.
          </ParagraphS1>

          <ParagraphS1>
            A few days later on the Sabbath, and with the knowledge that Keith and most of his
            henchmen are not in camp, Debreto and Johnny plus Sopai embark on their recon mission.
            Day and night they navigate dark foreboding rivers and canals aboard a bongo canoe, then
            hike through dense jungle to a position 300 meters astern of Keith’s camp. Staying well
            beyond the camp perimeter and out of danger, they case the joint with Debreto making
            sketches of the camp set-up along with sentry postings as they go. Mid-observation,
            Johnny steps through what they think is an animal ribcage; however, on closer inspection
            it is human. Via their infrared goggles they spot human skulls and bones galore – an
            open graveyard. As Johnny gags, Debreto frantically drags him away to avoid detection,
            but unbeknownst to them a tree mounted camera zooms in on them while retreating, and
            with their cheap facial cammo paint now worn off, they are made. While viewing their
            sketches and discussing a successful mission back at Debreto’s abode in Caracas, Johnny
            fumes knowing he may have trod all over his parents’ remains – he is now all in.
          </ParagraphS1>

          {/* <Button
              variant="contained"
              color="alternate"
              style={styles.label}
              href="https://drive.google.com/file/d/1SpNzBiId5CzhT5CDUogeLoZAFnHAFRKr/view"
              target="_blank"
              className="scriptExcerptButton"
            >
              View Script Excerpt
            </Button> */}

          <span className="custom-script-button">
            <a
              href="https://drive.google.com/file/d/1NTG-Cd9Y29b2vAFUA_fmznXKx6Dbl5tV/view?usp=…"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="script-synopsis-button">View Script Excerpt</span>
            </a>
          </span>

          <ParagraphS1>
            Keith lunches with Mick in the Caribbean where they foreshadow their upcoming
            {' '}
            <span style={styles.highlight}>golden jubilee celebration</span>
. Meanwhile, Bill
            arrives back in London incognito and surprises Charlie out at his Arabian horse stud
            farm in Devon. Not recognizing Bill (who has his back to us), Charlie moves in for a
            closer inspection and is astonished to see (POV) the
            {' '}
            <span style={styles.highlight}>
              <i>real</i>
              {' '}
Bill Wyman
            </span>
            {' '}
            standing before him who quips “Got a drink for an old friend?” Ad interim, Keith, now
            back in camp, is bemused by the CCTV footage of two foreign intruders. He prints a
            still, grabs an iPhone, exits his hut and calls over Mojo, who is playing football in
            the yard, and gives him both items and instructs him to keep an eye out for them.
          </ParagraphS1>

          <ParagraphS1>
            Worried sick about her husband, but knowing he would never sanction it, Babes arrives in
            Caracas unannounced. Livid, Debreto and Johnny go and pick her up at her downtown hotel;
            however, as they pull into the street, Mojo, who often hangs at a soda pop stand in the
            same vicinity, inadvertently spots them. After registering a possible match via Keith’s
            still, he swats pictures of them on his iPhone outside the hotel. When they depart, Mojo
            hurriedly jumps on his friend’s clapped-up motorcycle parked at the soda pop stand and
            follows them home, makes a note of their address, then departs. Later that night in
            Keith’s hut, Amazonas, one of his girls, the other is asleep in bed, playfully unties
            Keith’s rainbow colored bandana and ties it over her platinum blonde dyed hair before
            hopping back into bed. Keith, wild silver locks free, then moseys outside and
            accommodates his 14” coma free telescope for a gander, but is soon distracted by a hot
            young maiden walking by who invites him to her hut.
          </ParagraphS1>

          <ParagraphS1>
            Debreto receives confirmation that Keith is back in camp and the assassination is a go.
            After a tearful goodbye, Babes, who doesn’t buy their bogus excuse for the abrupt
            departure, re-enters the house. Later, astern of the camp perimeter, Debreto
            synchronizes his and Johnny’s watch for 20 minutes hence, then proceeds to his target
            further around the perimeter while Johnny creeps toward his. On clock, they take out
            their respective sentries then rendezvous behind Keith’s hut where they shuffle around
            opposite sides until meeting at the hut entrance – just like they rehearsed countless
            times back in the city (implied). Simultaneously, a black muscle car containing four
            armed thugs, faces unseen, pulls up out front of Debreto’s house in Caracas. A
            {' '}
            <i>scorpion-tattooed hand</i>
            {' '}
locks and loads a weapon. In Amazonas, Debreto and Johnny
            enter Keith’s hut A GUNS BLAZING. In Caracas, FLASHES of machine gun fire emanate from
            the front two rooms – one of them Babes’. In Amazonas, Debreto, via his infrared
            goggles, spies the wrecked corpse of the platinum blonde girl wearing Keith’s bandana
            and thinking she is Keith and that the job done, they make their escape back to Sopai.
          </ParagraphS1>

          <ParagraphS1>
            The ruckus awakes the camp along with Keith who exits his maiden’s hut and investigates.
            Incensed after discovering the carnage inside, he orders three sentries to give chase to
            the intruders. JUNGLE MUSIC FULL THROTTLE. Seeing the floodlit camp behind them, and
            with the chasing sentries hot on their heels, Debreto and Johnny bolt through jungle to
            a waiting Sopai who in turn leads them out of there, but their escape is slowed
            dramatically when Johnny rolls an ankle. With the chasing sentries now in earshot, they
            have no choice but to hide. Though concealed within brush, a loan sentry inadvertently
            heads straight for them. They ready for combat. Ten steps, nine, eight, seven,
            six-ROAR-a jaguar pounces on the sentry and devours him. Later, with the coast clear,
            Debreto, Johnny and Sopai reach the bongo and drift down stream; however, relief is
            abruptly fractured via a spotlight from the shore, followed by machine gun FIRE. After a
            tense exchange and several close calls, they make their escape.
          </ParagraphS1>

          <ParagraphS1>
            The next morning, Debreto and Johnny hitch a ride down river on a cargo boat. Johnny, a
            weight visibly lifted from his shoulders, tries to call Babes, but there is no signal.
            Meanwhile, at Debreto’s house in Caracas, a curtain lightly flaps through the
            machine-gunned window of Babes’ bedroom
            {' '}
            <span style={styles.highlight}>- THE END OF PART ONE</span>
          </ParagraphS1>

          <StoryBoardsCarousel />
        </Col>
      </Row>
    </Grid>
  </div>
);

export default P1Synopsis;
