import React from 'react';
import Helmet from 'react-helmet';
import { Grid, Row, Col } from 'react-bootstrap';
import BannerTextured from '../components/wrappers/BannerTextured';
import SubHeading from '../components/wrappers/SubHeading';
import Paragraph from '../components/wrappers/Paragraph';

const styles = {
  label: {
    fontSize: '12px',
    color: '#2F2F2F',
    letterSpacing: '.5px',
    fontFamily: 'Tahoma, Geneva, sans-serif',
    textDecoration: 'none',
    backgroundColor: '#E7E247',
    height: '20px',
  },
  underLine: {
    textDecoration: 'underline',
  },
  storyBoards: {
    marginTop: '40px',
  },
  bold: {
    fontWeight: 'bold',
  },
  red: {
    color: 'red',
  },
  highlightBold: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  highlight: {
    fontWeight: 'bold',
  },
};

const P2Synopsis = () => (
  <div>
    <Helmet>
      <title>Part 2: Sunset Synopsis</title>
      <meta name="description" content="" />
    </Helmet>

    {/* Page Banner */}
    <BannerTextured>
      <div className="container">
        <Row>
          <Col md={8} mdOffset={2}>
            <SubHeading>Part 2: Sunset Synopsis</SubHeading>
          </Col>
        </Row>
      </div>
    </BannerTextured>

    <Grid>
      <Row>
        <Col md={12} mdOffset={0}>
          <Paragraph>
            Rain pelts down on a neighborhood-wide black out. An unidentified sedan, sans lights,
            approaches Debreto’s house, loiters curbside momentarily before pulling into the drive.
            Inside, a HAND draws aside the curtains. As Debreto helps Johnny to his feet outside,
            the front door is YANKED opened; SHOCKED countenances abate as Babes rushes toward them.
            Inside, Debreto forages through some boxes (their recent move subtly portended in Part
            1; cloaked via camera angles et al post move) for a bottle of rum for a celebratory
            drink.
          </Paragraph>

          <Paragraph>
            In Amazonas meanwhile, Keith is enraged when Curtis informs him (verified via CCTV /
            Mojo’s pics) that it was none other than John Goodfellow
            {' '}
            <span style={styles.bold}>Jr </span>
            and a former associate of his father (Debreto) who almost snuffed him out. Later, Mick
            and Curtis pay Eddie a visit out in wine country. After incapacitating Eddie, Curtis
            tries to coerce him into giving up the whereabouts of his niece, thus Johnny and
            Debreto, but when he refuses he is executed. Eddie’s lover
            {' '}
            <span style={styles.bold}>Andy</span>
            {' '}
(50’s) (kidnapped earlier) is then dragged into
            the house and subjected to a sadistic wager: either call Babes and tell her of Eddie’s
            faux heart attack (to ferret out their whereabouts without signaling they’re on to them)
            or if he refuses, the Mexican father and son migrant workers (brought in earlier) will
            be slayed. To Mick’s surprise, he doesn’t yield, thus father and son are dispatched.
            Curtis then ups the anti by offering him the same proposition, but instead of migrant
            workers being on the line, it’s his kidnapped nieces (verified via a photo shown him).
            This time he quickly yields and leaves Babes a voicemail (as her cellphone is off)
            asking her to forward her flight details (as no doubt she will rush home to be with her
            sick uncle). Having already accommodated Andy’s cellphone, Curtis then disables Eddie’s,
            and they leave the premises.
          </Paragraph>

          {/* <Button
              variant="contained"
              color="alternate"
              style={styles.label}
              href="https://drive.google.com/file/d/1RPYxPv9J8g4HH-KBWeciPzFOE8DJxUKb/view"
              target="_blank"
              className="scriptExcerptButton"
            >
              View Script Excerpt
            </Button> */}

          <span className="custom-script-button">
            <a
              href="https://drive.google.com/file/d/1XxKFx_TV29sRZbw40BIRN-1i7oGA-HsN/view?usp=…"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="script-synopsis-button">View Script Excerpt</span>
            </a>
          </span>

          <Paragraph>
            In Caracas, after hearing Andy’s message, a devastated Babes calls him to enquire of her
            uncle’s status and to forward flight details, but his cell is off, thus she sends him a
            text. Cruising with Mick in the back of a limo in the Napa area, Curtis turns on Andy’s
            cell, and Babes’ text (with flight details) appears to which he passes on to Mick who in
            turn forwards to Keith. Andy (stowed in the trunk) is then assassinated and dumped over
            a bridge. Later the same night in Caracas, mission accomplished and confidant of their
            non-detection, Debreto and Johnny finalize their exit route from Venezuela via a four
            hour drive to Guiria followed by a short ferry hop to Trinidad, to be undertaken
            immediately after dropping Babes off at the airport (deemed safe for her; too risky for
            themselves) the following morning. In La Mision, meanwhile, a crowd of townspeople look
            up at the church spire, and Sopai crucified to it.
          </Paragraph>

          <Paragraph>
            The next day, Debreto drops the Goodfellows off at the departure terminal then circles
            the parking lot awaiting Johnny’s return. Meanwhile, an
            {' '}
            <span style={styles.bold}>unidentified male</span>
            {' '}
(seen from the waist down) loiters
            outside the terminal with a suitcase at his feet. As he draws on his cigarette, the
            {' '}
            <i>scorpion tattoo on the back of his hand</i>
            {' '}
is discernable. Via his POV (throughout
            the rest of the scene), the unidentified male then enters the terminal and makes a
            beeline for the American Airlines counter where he clocks his targets, Johnny and Babes,
            whom having just checked-in, embark for immigration control. Post check-in, he shadows
            Johnny who is by now heading for the exit. Meanwhile, as his case tracks through to
            baggage control, a staff member lingering nearby plucks it off the carousal and
            disappears with it. Outside the terminal, Johnny climbs into Debreto’s sedan and they
            leave without a hitch. Shortly after, the unidentified male rushes out and impatiently
            waits for his ride to arrive. Several moments later, a black muscle car picks him up
            then departs in haste after the sedan.
          </Paragraph>

          <Paragraph>
            Later, en route to Guiria, Debreto and Johnny pull in to a diner for breakfast. While
            chowing down, a news flash appears on the TV announcing the crash of American Airlines
            AA180 (Babes’ plane). Debreto drags a hysterical Johnny out of the restaurant and they
            hightail it out of there, but before long the black muscle car is hot on their heels.
            After exchanging fire, Johnny, with a lucky shot, hits a tire and it fishtails off the
            road, but just as they think they’re home free, a drunk driver coming the other way
            forces Debreto off the road: CRASH! Johnny, sans seatbelt, is out cold while Debreto,
            although injured, manages to exit the car and dive into nearby bushes just as the black
            muscle car pulls up behind the sedan. The front passenger door clicks ajar and a
            {' '}
            <i> scorpion-tattooed hand</i>
            {' '}
helps
            {' '}
            <span style={styles.bold}>Javier</span>
            {' '}
(late 20’s), cocky capo in the Outfit, emerge
            from the car. After ordering his men to put an unconscious Johnny in the trunk then fan
            out and find Debreto, he makes a call on his cell to which Debreto overhears him confirm
            that Johnny will be delivered to a ship at Dock 3, Ciudad Guyana before it sets sail at
            midnight that night. With time against them, Javier calls in his thugs empty-handed and
            they depart. Later, with the coast clear, a demoralized Debreto limps off down the road.
          </Paragraph>

          {/* <Button
              variant="contained"
              color="alternate"
              style={styles.label}
              href="https://drive.google.com/file/d/1xHwDooqMA-fkwD-fLdWFbJI-pBE5_zv7/view"
              target="_blank"
              className="scriptExcerptButton"
            >
              View Script Excerpt
            </Button> */}

          <span className="custom-script-button">
            <a
              href="https://drive.google.com/file/d/171taOh2L1hk2BQpeE17AzgcXM4-7pa1u/view?usp=…"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="script-synopsis-button">View Script Excerpt</span>
            </a>
          </span>

          <Paragraph>
            That evening, Johnny awakes in a cage entombed in the cargo hold of the ‘Gin & Tonic’, a
            general freighter readying for departure at Dock 3, Ciudad Guyana. Meanwhile, at a safe
            house in Caracas, Debreto, resigned to the bleak reality, snorts some coke to offset his
            utter exhaustion then gets to prepping the arsenal of weapons laid out on the bed behind
            him. Later, with duffle bag on shoulder, he boards a bus to Guyana, and after arriving
            some hours later at Dock 3, surreptitiously boards the Gin & Tonic and hides in the
            starboard lifeboat. Shortly after, Keith and Mick (who flew in from San Francisco as
            previously planned) arrive and board. A large booty chest follows them aboard and the
            ship departs soon after.
          </Paragraph>

          <Paragraph>
            The next afternoon, the Gin & Tonic departs the mainland for the Atlantic. Later that
            night, under cover of a storm, Debreto surprises Johnny in the hold. After suffering a
            meltdown on learning that Keith is still alive, Keith and Mick are on board, and they’re
            heading to London, hope returns when Debreto briefs him on his plan to take the ship
            during the next storm. In England, meanwhile, after discussing Queen Elizabeth II’s
            upcoming diamond jubilee, BBC news anchors report the rumor of the Outfit’s
            {' '}
            <span style={{ fontWeight: '900', textDecoration: 'none' }}>
              impending golden jubilee
            </span>
            .
          </Paragraph>

          <Paragraph>
            Halfway across the Atlantic, a raging storm throws the Gin & Tonic around like a rag
            doll. Freed from his cage, Johnny finds his legs, fatigued from incarceration,
            nonresponsive, thus Debreto shoves a bump of coke in his face to which he vehemently
            declines, but on realization that it is his only avenue to mobility, submits - both have
            now unwittingly succumbed to past nemeses. After taking out two guards on the main deck,
            one via an unnecessary gunshot from a high and jittery Johnny, they head sternward to
            the 01 deck to take out the crew in the wheelhouse. Meanwhile, in said wheelhouse,
            {' '}
            <span style={styles.bold}>Jose</span>
            {' '}
(30’s), the second mate, and
            {' '}
            <span style={styles.bold}>Sebastian </span>
            {' '}
(30’s), the third mate, hear the gunshot
            (time delay), which they mistake as rigging cable snapping. Jose, peeved that the two
            guards didn’t pick up their walkie-talkie, goes and investigates but finds no one there.
            Thinking that they’ve snuck below deck for some kip, Jose orders Sebastian to go and
            fetch them prior to commencing a deck-wide rigging inspection.
          </Paragraph>

          <Paragraph>
            As Debreto ascends the starboard wheelhouse stairs, Sebastian descends portside. Johnny
            (keeping lookout on the 01 deck) darts out and confronts Sebastian, who jumps him and a
            mortal struggle ensues. Sebastian gains the upper hand and is about to blow Johnny’s
            head off when WHACK-Debreto’s pistol butt rains down on his skull. Meanwhile, inspection
            complete, Jose returns to the wheelhouse oblivious to the person lying horizontal
            against the wheelhouse wall (cloaked by shadows, his haste and an otherwise clear deck),
            but on finding it vacant, and a call sent below unanswered, he becomes alarmed. Armed,
            he cautiously inspects the 01 deck and spots what he thinks is Sebastian’s body, but is
            shocked to find Johnny instead. Behind the lifeboat meanwhile, Debreto SNAPS Sebastian’s
            neck, then while exchanging clothes (soaked by a rogue wave earlier) with the deceased,
            hears Johnny SCREAM. Adjacent the wheelhouse, Johnny closes his eyes; Jose strangles the
            trigger… BANG-Debreto drops him from across deck. Later, peak storm, with the lower deck
            hatch in sight, and the Dons in touching distance, SNAP-a container sheds its cables and
            tumbles toward them; Debreto shoves Johnny (hits his head and is KO’d) aside in the nick
            of time, but he himself gets NAILED-game over.
          </Paragraph>

          {/* <Button
              variant="contained"
              color="alternate"
              style={styles.label}
              href="https://drive.google.com/file/d/1twkGC1ZgNw6t-3wNBpeC6MdFlv1x3jN8/view"
              target="_blank"
              className="scriptExcerptButton"
            >
              View Script Excerpt
            </Button> */}

          <span className="custom-script-button">
            <a
              href="https://drive.google.com/file/d/1n5a2VEpBGa0sDgXDJORY3KivA4qmziyS/view?usp=…"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="script-synopsis-button">View Script Excerpt</span>
            </a>
          </span>

          <Paragraph>
            The next day, Keith, flabbergasted by another near miss, intimates to Mick that Johnny
            will be fed to the fishes. Later, on the evening of
            {' '}
            <span style={styles.highlight}>July 11, 2012</span>
, the Gin & Tonic drops anchor in the
            Thames Estuary, and Mick, Keith and the booty chest board a waiting speedboat, which
            then hurtles up the Thames. Concurrently, Pete drives Charlie, Ronnie and Bill through
            London, passing 136 Oxford Street (with the façade of the original Marquee Club - the
            site of the Stones’ first gig on
            {' '}
            <span style={styles.highlight}>July 12, 1962</span>
            {' '}
-
            superimposed over the current establishment) en route to an ancient pub located in a
            quiet residential area across from Wembley Stadium. In the pub’s poolroom, Pete opens a
            secret door concealed behind a dartboard, and the quartet descend a spiraling staircase
            to a subterranean passageway where after a solemn procession they arrive at the Inner
            Sanctum. Having docked in West London and in turn driven to the same pub ad interim,
            Mick and Keith traverse the secret chamber.
          </Paragraph>

          <Paragraph>
            Inside the Sanctum, after some revelry, the Dons light stogies, pour scotches, then rise
            and face the grandfather clock on the wall. As the clock strikes twelve (
            <span style={styles.highlight}>marking their jubilee</span>
            ), five separate CHIMES correspond with a headshot of each Don in turn. On the sixth
            CHIME they pivot and salute
            {' '}
            <span style={styles.bold}>Ian Stewart’s</span>
            {' '}
(a deceased
            6th Don and founding member) portrait on the wall. Later, Keith suddenly remembers his
            surprise gift for his cohorts, thus drags the booty chest in from the anteroom (transported
            in earlier) and presents a petrified Johnny to the bemused table. After divulging his
            identity, the Dons taunt and assault him until he blacks out.
          </Paragraph>

          <Paragraph>
            Mid fry-up, post festivities, Keith has an epiphany, and thus with
            {' '}
            <span style={styles.underLine}>shears</span>
            {' '}
found under the sink,
            {' '}
            <span style={styles.underLine}>lops off the fingers on Johnny’s left hand</span>
, throws
            them into the pan and in turn serves them mixed in with real English bangers to an
            unsuspecting hungry table, who post revelation are repulsed and Keith admonished, but
            quickly forgiven. Later, after placing Johnny back in the chest and fastening the lid
            (the O2 mask keeping him alive prior accidently left outside the box), Pete diverts to
            the bar to put on a new record. At the table, meanwhile, with new cigars lit and drinks
            poured, the contented Dons eye Mick who initiates a story: immediately after orating
            “There were these five guys”, (freeze frame) the song ‘Time On My Side’ commences
            (credits roll).
          </Paragraph>

          <Paragraph>
            (Coda) Pete awakes post party, checks his watch, and horrified at the time, promptly
            arouses the Dons and leads them through another secret passageway, up some stairs, and
            opens a door to BLINDING LIGHT… Outside, The Rolling Stones (themselves) exit the void
            to the backstage area of a
            {' '}
            <i>real life</i>
            {' '}
Stones concert at Wembley Stadium. “Ladies
            and gentlemen…” As the crowd goes berserk; curtains part to a set sun on the horizon,
            the band walks on stage and just before the first riff is played or word spoken
            (freeze-frame) –
            <span style={styles.bold}>THE END</span>
          </Paragraph>
        </Col>
      </Row>
    </Grid>
  </div>
);

export default P2Synopsis;
