import React, { Component, Fragment } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import LoaderButton from "../components/LoadingIndicator/LoaderButton";
import "./Login.css";
import BannerTextured from "../components/wrappers/BannerTextured";
import SubHeading from "../components/wrappers/SubHeading";

import { Auth } from "aws-amplify";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      username: "",
      password: "",
      newPassword: "",
      newUser: null
    };
  }

  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }

  validateChangePasswordForm() {
    return this.state.newPassword.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.signIn(this.state.username, this.state.password).then(user => {
        // alert("Logged in");
        // console.log(user);
        // console.log(`The challengeName: ${user.challengeName}`);

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          // this.props.history.push("/change-password");
          this.setState({ newUser: user });
        } else {
          this.props.userHasAuthenticated(true);
          // this.props.history.push("/");
        }
      });
    } catch (e) {
      alert(e.message);
    }
    this.setState({ isLoading: false });
  };

  handleChangePasswordSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.signIn(this.state.username, this.state.password).then(user => {
        // alert("changePassword");
        // console.log(user);
        // console.log(`The challengeName: ${user.challengeName}`);
      });

      await Auth.completeNewPassword(
        this.state.newUser,
        this.state.newPassword,
        this.state.newUser.challengeParam.requiredAttributes
      );
      // alert("have set new password");
      this.props.userHasAuthenticated(true);
      // this.props.history.push("/");
    } catch (e) {
      alert(e.message);
      // console.log(`The error message: ${e.message}`);
      this.setState({ isLoading: false });
    }
  };

  renderForm() {
    return (
      <Fragment>
        <Grid>
          <Row>
            <Col md={12} mdOffset={0}>
              <div className="Login">
                <form onSubmit={this.handleSubmit}>
                  <FormGroup controlId="username" bsSize="large">
                    <ControlLabel>Username</ControlLabel>
                    <FormControl
                      autoFocus
                      type="text"
                      value={this.state.username}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <FormGroup controlId="password" bsSize="large">
                    <ControlLabel>Password</ControlLabel>
                    <FormControl
                      value={this.state.password}
                      onChange={this.handleChange}
                      type="password"
                    />
                  </FormGroup>
                  <LoaderButton
                    block
                    bsSize="large"
                    disabled={!this.validateForm()}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Login"
                    loadingText="Logging in…"
                  />
                </form>
              </div>
            </Col>
          </Row>
        </Grid>
      </Fragment>
    );
  }

  renderChangePasswordForm() {
    return (
      <Fragment>
        <div className="container" style={{ paddingTop: "40px" }}>
          <Row>
            <Col md={12} mdOffset={0} style={{ textAlign: "center" }}>
              <p>
                <i>First time users are required to change their password.</i>
              </p>
              <p>Please enter your new password below.</p>
            </Col>
          </Row>
          <div className="Login">
            <form onSubmit={this.handleChangePasswordSubmit}>
              <FormGroup controlId="newPassword" bsSize="large">
                <ControlLabel>New Password</ControlLabel>
                <FormControl
                  value={this.state.newPassword}
                  onChange={this.handleChange}
                  type="password"
                />
                {/* <HelpBlock>Please change your password.</HelpBlock> */}
              </FormGroup>
              <LoaderButton
                block
                bsSize="large"
                disabled={!this.validateChangePasswordForm()}
                type="submit"
                isLoading={this.state.isLoading}
                text="Change"
                loadingText="Updating…"
              />
            </form>
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <Fragment>
        <BannerTextured>
          <div className="container">
            <Row>
              <Col md={12} mdOffset={0}>
                <SubHeading>Login</SubHeading>
              </Col>
            </Row>
          </div>
        </BannerTextured>
        {this.state.newUser === null
          ? this.renderForm()
          : this.renderChangePasswordForm()}
      </Fragment>
    );
  }
}
