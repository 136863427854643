import React from 'react';
import Helmet from 'react-helmet';
import { Grid, Row, Col } from 'react-bootstrap';

import BannerTextured from '../components/wrappers/BannerTextured';
import SubHeading from '../components/wrappers/SubHeading';
import Paragraph from '../components/wrappers/Paragraph';

const styles = {
  Genesis: {
    paddingTop: '20px',
    paddingBottom: '20px',
    textAlign: 'left',
  },
  Preamble: {
    textAlign: 'center',
  },
};

const GenesisPreamble = () => (
  <div>
    <Helmet>
      <title>Genesis & Preamble</title>
      <meta name="description" content="" />
    </Helmet>

    {/* Page Banner */}
    <BannerTextured>
      <div className="container">
        <Row>
          <Col md={12} mdOffset={0}>
            <SubHeading>Genesis</SubHeading>
            <h4 style={styles.Genesis}>
              Post long commute home from a tedious 9-5; a cursory salute to
              parents en route to my room, I, along with the help of three close
              friends, R. Coke, M. Jane, and R. N. Roll, waste no time escaping
              my pathetic existence. Later, ‘Rolling Stones: Still Life -
              American Concert 81’ makes its way on to the playlist, and air
              guitar and joyous howling are now de rigueur: ‘Just my
              imaginationnnn, runnin’ away with meeee’… My last dance with Mary
              ushers me toward full trance. My band mates are a blur, the
              deafening music muffles, then FLASH: I’m standing high upon the
              scaffolding looking down upon five stickmen prance the stage. I
              look up; take a swill of the opaque vista, then FLASH: The same
              five guys now huddle around a table puffing on stogies. The room
              is dark; the mood solemn,
              <i> and they’re not packing instruments…</i>
            </h4>
            <p
              style={{
                textAlign: 'center',
                color: 'lightgray',
                marginTop: '20px',
                paddingBottom: '10px',
                fontSize: '23px',
              }}
            >
              Fast-forward to present day
            </p>
          </Col>
        </Row>
      </div>
    </BannerTextured>

    <Grid>
      <Row>
        <Col md={12} mdOffset={0}>
          <SubHeading style={styles.Preamble}>Preamble</SubHeading>
          <Paragraph>
            In the world of Jubilee Blues, Messrs. Jagger, Richards, Watts,
            Wood and Wyman are no longer the five principals of the greatest
            rock ‘n’ roll band in the world - The Rolling Stones - but rather
            the five Dons of the most powerful and feared criminal organisation
            on the planet - the infamous Crawdaddy Crew (aka the Crawdaddy Crime
            Cartel; the Outfit). Factual events/anecdotes pertaining to the
            band’s history, the personalities of/dynamics between band members
            etcetera along with a myriad of other Stones related nuance are
            interwoven into the fictional gangster narrative while the
            overarching theme is the sun setting on their criminal (musical)
            careers, culminating in the Outfit’s (band’s) golden jubilee
            celebration on July 12, 2012. Ultimately, though, via a dark comedic
            prism, it is a tribute to the band and its members.
          </Paragraph>

          <Paragraph>
            Although the narrative incorporates six ‘names’ (the five said
            principals + one), Jubilee Blues is not a celebrity vehicle, nor is
            it a Stones vehicle per se as their song catalogue is excluded while
            all authentic photos/newspaper clippings of band members utilized in
            investigative scenes are represented/doctored in such a way to avert
            reference to The Rolling Stones in any way, shape or form. Moreover,
            all double entendres are a natural part of the fictional narrative,
            thus posing zero hindrance for non-fans while providing a double pay
            off for fans in the know. Furthermore, Stones nuance, tastefully
            imbued and by no means saturated, is multi-layered, thus pleasing
            both aficionados and novice fans. As a result, depending on
            individual audience member disposition, the antagonists (Jagger &
            Co) may well get as many cheers as the protagonists (two main
            leads).
          </Paragraph>

          <Paragraph>
            Make no mistake, this project was written specifically for the
            participation of Jagger & Co with their advancing age and dearth of
            acting experience taken into account while all scenes involving the
            sextet are non-physically challenging and contain mostly succinct
            dialogue, thus are comfortably within their limits. In actuality,
            they just play themselves albeit in caricature, yet should a scene
            prove too arduous then assistance via body doubles/digital face
            swaps is a viable option while straight impersonation and full
            animation are alternatives. That said,
            {' '}
            <span style={{ fontWeight: '900' }}>
              it is the two main leads along with a strong supporting cast who
              do the heavy lifting in terms of acting.
            </span>
          </Paragraph>

          <Paragraph style={{ textAlign: 'center' }}>
            Thus, this project is both
            {' '}
            <span style={{ fontWeight: '900' }}>realistic</span>
            {' '}
and
            {' '}
            <span style={{ fontWeight: '900' }}>attainable.</span>
          </Paragraph>

          <Paragraph
            style={{
              textAlign: 'center',
              color: 'lightgray',
              paddingBottom: '10px',
            }}
          >
            If postured in front of Jagger & Richards, the concept will sell
            itself
          </Paragraph>
        </Col>
      </Row>
    </Grid>
  </div>
);

export default GenesisPreamble;
