import styled from "styled-components";

const ListGroupItemCustomSquare = styled.li`
  list-style-position: outside;
  list-style-type: square;
  padding-top: 10px;
  font-size: 23px;
  line-height: 1.25em;
  // font-family: Helvetica, Arial, "Lucida Grande", sans-serif;
  font-family: Arial, Arial, Helvetica, sans-serif;
  margin-left: 20px;

  @media (max-width: 992px) {
    min-height: 70px;
  }
  @media (max-width: 768px) {
    min-height: 70px;
  }
  @media (max-width: 420px) {
    min-height: 70px;
    margin-left: 0px;
  }
`;

export default ListGroupItemCustomSquare;
