import React from 'react';
import styled from 'styled-components';
import { Row, Col, Panel } from 'react-bootstrap';
import ListGroupItemCustom from './wrappers/ListGroupItemCustom';
import ListGroupItemCustomSquare from './wrappers/ListGroupItemCustomSquare';

const styles = {
  panelStyle: {
    textAlign: 'left',
    borderStyle: 'none',
    color: '#001c3d',
    marginBottom: '0px',
  },
  panelTitle: {
    background: '#D2D3DB',
    color: '#001c3d',
    marginTop: '0px',
    marginBottom: '0px',
    textAlign: 'center',
    paddingTop: '12px',
    paddingBottom: '12px',
    fontSize: '25px',
    // fontFamily: 'Helvetica, Arial, "Lucida Grande", sans-serif'
    fontFamily: 'Arial, Arial, Helvetica, sans-serif',
  },
  listGroupTitle: {
    background: 'white',
    fontWeight: 'bold',
    fontSize: '24px',
    color: '#001c3d',
    listStyle: 'none',
    textAlign: 'center',
    fontFamily: 'Arial, Arial, Helvetica, sans-serif',
  },
  listGroupTitleGrey: {
    background: 'white',
    fontWeight: 'bold',
    fontSize: '24px',
    color: 'lightgray',
    listStyle: 'none',
    textAlign: 'center',
    fontFamily: 'Arial, Arial, Helvetica, sans-serif',
  },
  listGroupTitle2: {
    background: 'white',
    fontWeight: 'bold',
    fontSize: '1.8em',
    color: '#001c3d',
    listStyle: 'none',
    textAlign: 'center',
  },
  listGroupSubTitle: {
    fontSize: '23px',
    color: '#001c3d',
    listStyle: 'none',
    textAlign: 'center',
    fontFamily: 'Arial, Arial, Helvetica, sans-serif',
  },
  listGroupSubTitle2: {
    fontSize: '23px',
    color: '#001c3d',
    listStyle: 'none',
    textAlign: 'center',
    fontFamily: 'Arial, Arial, Helvetica, sans-serif',
    lineHeight: '29px',
  },
  listItemBorder: {
    borderRightStyle: 'none',
  },
  panelBodyLeft: {
    borderRight: 'none',
    paddingLeft: '60px',
    paddingRight: '45px',
  },
  panelBodyRight: {
    borderRight: 'none',
    paddingLeft: '45px',
    paddingRight: '60px',
  },
  panelBody: {
    borderRight: 'none',
    paddingLeft: '45px',
    paddingRight: '45px',
    paddingBottom: '25px',
  },
  listSubTitle: {
    listStyle: 'none',
  },
  listCenter: {
    textAlign: 'center',
    listStyle: 'inside',
  },
  rowStyle: {
    paddingBottom: '0px',
  },
};

const CustomDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BoxOfficeTable = () => (
  <div className="container">
    <Row className="container">
      <Panel style={styles.panelStyle}>
        <Panel.Heading style={styles.panelTitle} componentClass="h3">
          BUILT-IN AUDIENCE
        </Panel.Heading>
        <Row style={styles.rowStyle}>
          <Col md={6} mdOffset={0}>
            <Panel.Body style={styles.panelBodyLeft}>
              <Panel.Heading>
                <Panel.Title
                  className="ListGroupTitle"
                  style={styles.listGroupTitle}
                >
                  A preliminary pool of several hundred million to draw from
                </Panel.Title>
              </Panel.Heading>

              <ul>
                <ListGroupItemCustom>
                  220 million album sales (Stones)
                </ListGroupItemCustom>
                <ListGroupItemCustom>
                  100 million album sales (Who)
                </ListGroupItemCustom>
              </ul>
            </Panel.Body>
          </Col>

          <Col md={6} mdOffset={0} className="column-border-left">
            <Panel.Body style={styles.panelBodyRight}>
              <Panel.Heading>
                <Panel.Title
                  className="ListGroupTitle"
                  style={styles.listGroupTitle}
                >
                  A (conservative) core audience of 5,000,000+
                </Panel.Title>
              </Panel.Heading>

              <ul>
                <ListGroupItemCustom className="list-marker">
                  2005-7 A Bigger Bang world tour sold
                  {' '}
                  <span style={{ fontWeight: 'bold' }}>4,680,000</span>
                  {' '}
tickets
                  (Stones)
                </ListGroupItemCustom>
                <ListGroupItemCustom>
                  2012-13 & 2014-16 world tours sold
                  {' '}
                  <span style={{ fontWeight: 'bold' }}>674,000</span>
                  {' '}
tickets
                  (Who)
                </ListGroupItemCustom>
              </ul>
            </Panel.Body>
          </Col>
        </Row>
      </Panel>
    </Row>

    <Row className="container">
      <Panel style={styles.panelStyle}>
        <Panel.Heading style={styles.panelTitle} componentClass="h3">
          MERCHANDISE
        </Panel.Heading>

        <Row>
          <Col md={6} mdOffset={0} style={{ textAlign: 'center' }}>
            <Panel.Body style={styles.panelBodyLeft}>
              <Panel.Heading>
                <Panel.Title
                  className="ListGroupTitle"
                  style={styles.listGroupTitle}
                >
                  Millions of cashed-up Boomers and X’ers
                </Panel.Title>
              </Panel.Heading>

              <Panel.Heading>
                <Panel.Title
                  className="ListGroupTitle"
                  style={styles.listGroupSubTitle2}
                >
                  Average prices paid for Stones tickets in 2012, 2013 (50th
                  anniversary shows) and 2018 respectively:
                </Panel.Title>
              </Panel.Heading>

              <ul style={{ paddingLeft: '0px' }}>
                <ListGroupItemCustom className="list-custom">
                  <span style={{ fontWeight: 'bold' }}>$115</span>
                  {' '}
(low) /
                  {' '}
                  <span style={{ fontWeight: 'bold' }}>$813</span>
                  {' '}
(high)
                </ListGroupItemCustom>
                <ListGroupItemCustom className="list-custom">
                  <span style={{ fontWeight: 'bold' }}>$107</span>
                  {' '}
/
                  {' '}
                  <span style={{ fontWeight: 'bold' }}>$783</span>
                </ListGroupItemCustom>
                <ListGroupItemCustom className="list-custom">
                  <span style={{ fontWeight: 'bold' }}>$93</span>
                  {' '}
/
                  {' '}
                  <span style={{ fontWeight: 'bold' }}>$626</span>
                </ListGroupItemCustom>
              </ul>
            </Panel.Body>
          </Col>

          <Col md={6} mdOffset={0} className="column-border-left">
            <Panel.Body style={styles.panelBodyRight}>
              <Panel.Heading>
                <Panel.Title
                  className="ListGroupTitle"
                  style={styles.listGroupTitle}
                >
                  A plethora of merchandising possibilities
                </Panel.Title>
              </Panel.Heading>

              <ul>
                <ListGroupItemCustom>
                  Strong market demand for adult themed merchandise
                </ListGroupItemCustom>
                <ListGroupItemCustom>
                  A loyal core audience with extant strong emotional attachment
                  underpinning fictional character rapport
                </ListGroupItemCustom>
                <ListGroupItemCustom>
                  Built atop/alongside a proven brand with a mature and highly
                  successful merchandise trade already in place
                </ListGroupItemCustom>
                <ListGroupItemCustom>
                  In addition to a myriad of movie/music cross promotional
                  options
                </ListGroupItemCustom>
              </ul>
            </Panel.Body>
          </Col>
        </Row>
      </Panel>
    </Row>

    <Row className="container">
      <Panel style={styles.panelStyle}>
        <Panel.Heading style={styles.panelTitle} componentClass="h3">
          CLASSIC ROCK AUDIENCE DRAW
        </Panel.Heading>

        <Panel.Body style={styles.panelBody}>
          <Panel.Heading>
            <Panel.Title style={styles.listGroupTitle}>
              Although not a biopic, concert film or musical, the direct involvement
              of two pillars of the genre will no doubt draw an audience from
              classic rock fans in particular and rock fans in general
            </Panel.Title>
          </Panel.Heading>

          <ul>
            <ListGroupItemCustom>
              38.28 million Americans tuned into classic rock radio programs in
              the spring of 2017
            </ListGroupItemCustom>
            <ListGroupItemCustom>
              The classic rock listener demographic has grown younger with
              Nielsen (2014) ranking classic rock as the ninth most listened to
              format by millennials
            </ListGroupItemCustom>
            <ListGroupItemCustom>
              On any given year, rock is either the most popular music genre or a close second both globally and in the US
            </ListGroupItemCustom>
          </ul>
        </Panel.Body>
      </Panel>
    </Row>

    <Row className="container">
      <Panel style={styles.panelStyle}>
        <Panel.Heading style={styles.panelTitle} componentClass="h3">
          GENRE AUDIENCE DRAW
        </Panel.Heading>

        <Panel.Body style={styles.panelBody}>
          <Panel.Heading>
            <Panel.Title style={styles.listGroupTitle}>
              As all double entendres are a natural part of the fictional
              narrative, posing zero hindrance for non-fans, crime
              drama/adventure/dark comedy fans who have no interest in rock
              music and/or are completely ignorant of the Stones will still be
              drawn to the project
            </Panel.Title>
          </Panel.Heading>

          <ul>
            <ListGroupItemCustom>
              Market share via genre in North America 1995-2018: (1) Adventure
              27.20%; (3) Drama 16.35%; (10) Dark Comedy 0.65%
            </ListGroupItemCustom>
          </ul>
        </Panel.Body>
      </Panel>
    </Row>

    <Row className="container">
      <Panel style={styles.panelStyle}>
        <Panel.Heading style={styles.panelTitle} componentClass="h3">
          PROVEN CONTEMPORARY GLOBAL BOX OFFICE DRAW
        </Panel.Heading>

        <Panel.Body style={styles.panelBody}>
          <ul>
            <ListGroupItemCustom>
              Richards’ (2010) & Townsend’s (2012) biographies made the NY Times
              best sellers list
            </ListGroupItemCustom>
            <ListGroupItemCustom>
              The Stones’ most recent album Blue & Lonesome (2016) sold 2
              million units; reached #1 on the UK Album Charts and #4 on the
              Billboard 200
            </ListGroupItemCustom>
            <ListGroupItemCustom>
              The Who Tour 2017 sold out arenas across the US
            </ListGroupItemCustom>
            <ListGroupItemCustom>
              Stones’ tours in Asia/Oceana 2013, North America 2015, Latin
              America 2016 and Europe 2017-18 sold out stadia in minutes
            </ListGroupItemCustom>
          </ul>
        </Panel.Body>
      </Panel>
    </Row>

    <Row className="container">
      <Panel style={styles.panelStyle}>
        <Panel.Heading style={styles.panelTitle} componentClass="h3">
          STILL RELEVANT GOING FORWARD
        </Panel.Heading>

        <Panel.Body style={styles.panelBody}>
          <ul>
            <ListGroupItemCustom>
              The Stones will embark on a sold out US stadium tour in 2019
            </ListGroupItemCustom>
            <ListGroupItemCustom>
              The band has confirmed that a new album of original material is in
              the works; a limited supporting tour post release is not
              far-fetched
            </ListGroupItemCustom>
            <ListGroupItemCustom>
              Band members have dismissed talk of imminent retirement
            </ListGroupItemCustom>
            <ListGroupItemCustom>
              The Who will also tour the US and release an album of original material in 2019
            </ListGroupItemCustom>
          </ul>
        </Panel.Body>
      </Panel>
    </Row>

    <Row className="container">
      <Panel style={styles.panelStyle}>
        <Panel.Heading style={styles.panelTitle} componentClass="h3">
          ADD TALENT WITH BOX OFFICE DRAW
        </Panel.Heading>

        <Panel.Body style={styles.panelBody}>
          <Panel.Heading>
            <Panel.Title style={styles.listGroupTitle}>
              Due to the universal esteem held for said principals, their
              involvement should have no problem attracting (i) a name director;
              (ii) two high-profile leads; (iii) a supporting cast with strong
              box office pedigree
            </Panel.Title>
          </Panel.Heading>

          <ul>
            <ListGroupItemCustom>
              The two leads and supporting cast are allotted the vast majority
              of screen time
            </ListGroupItemCustom>
          </ul>
        </Panel.Body>
      </Panel>
    </Row>

    <Row className="container">
      <Panel style={styles.panelStyle}>
        <Panel.Heading style={styles.panelTitle} componentClass="h3">
          STRONG R-RATED MOVIE MARKET
        </Panel.Heading>

        <Panel.Body style={styles.panelBody}>
          <ul>
            <ListGroupItemCustom>
              R-Rated movies grossed $2.749 billion and $2.668 billion at the US box office in 2017 and 2018 respectively
            </ListGroupItemCustom>
            <ListGroupItemCustom>
              The top 10 in 2017 / 2018 grossed $1.454 / $1.311 billion (US); $2.011 / $1.572 billion (international); $3.465 / $2.883 billion (total)
            </ListGroupItemCustom>
          </ul>
        </Panel.Body>
      </Panel>
    </Row>

    <Row className="container">
      <Panel style={styles.panelStyle}>
        <Panel.Heading style={styles.panelTitle} componentClass="h3">
          STRONG MUSIC BIOPIC MARKET
        </Panel.Heading>

        <Panel.Body style={styles.panelBody}>
          <Panel.Heading>
            <Panel.Title style={styles.listGroupTitle}>
              Although not a music biopic, Jubilee Blues is unique in the fact that it has the same iconological draw of a biopic
              {' '}
              <i>in addition</i>
              {' '}
to the genre-talent draw of a non-music fictional narrative
            </Panel.Title>
          </Panel.Heading>

          <ul>
            <ListGroupItemCustom>
              Parallels can be drawn between the iconological significance of Jubilee Blues and the two most successful music biopics of all-time: Straight Outta Compton and Bohemian Rhapsody (BR), as what N.W.A (& associated solo acts) are to hip hop, the Stones are to rock ‘n’ roll - universally esteemed pioneers of their genre; and while classic rock icons Mercury/Queen are, Jagger 
{' '}
<i>and</i>
{' '}
Richards hold at least equal allure to the former while the Stones summit the latter in the classic rock pantheon
</ListGroupItemCustom>
            <ListGroupItemCustom>
              Iconological draw
              {' '}
              <i>backed</i>
              {' '}
by voluminous commercial success strongly correlates to a profitable release: out of the 17 music biopic dramas produced this decade (2010-2018), the 7 that delivered a return on investment (based upon on dom/int. box office receipts alone) were backed by icon album et al. sales in excess of 50 million, whereas associated album sales of the 10 box office flops were
              {' '}
              <i>well below</i>
              {' '}
that figure. In fact, a few outliers notwithstanding, this pattern applies to the majority of the 19 (ROI); 32 (flops); 51 (total) music biopic dramas ever produced
            </ListGroupItemCustom>
            <ul style={{ margin: '10px 40px 10px 0px' }}>
              <ListGroupItemCustomSquare>
                <CustomDiv className="market-sales-div">
                  <span className="market-sales-movie" style={{ width: '250px', margin: '0 10px 0 0px' }}>
                    Bohemian Rhapsody
                  </span>
                  <span className="market-sales-artist">
                    Queen
                  </span>
                  <span className="market-sales-queen">
                    200m album sales
                  </span>
                </CustomDiv>
              </ListGroupItemCustomSquare>
              <ListGroupItemCustomSquare>
                <CustomDiv className="market-sales-div">
                  <span className="market-sales-movie" style={{ width: '250px', margin: '0 10px 0 0px' }}>
                    Straight Outta Compton
                  </span>
                  <span className="market-sales-artist">
                    N.W.A + solo acts
                  </span>
                  <span className="market-sales-nwa">
                    50m album sales
                  </span>
                </CustomDiv>
              </ListGroupItemCustomSquare>
              <ListGroupItemCustomSquare style={{ fontWeight: 'bold' }}>
                <CustomDiv className="market-sales-div">
                  <span className="market-sales-movie" style={{ width: '250px', margin: '0 10px 0 0px' }}>
                    Jubilee Blues
                  </span>
                  <span className="market-sales-artist">
                    Stones
                  </span>
                  <span className="market-sales-stones">
                    220m album sales
                  </span>
                </CustomDiv>
              </ListGroupItemCustomSquare>
            </ul>
            <ListGroupItemCustom>
              Even though audiences have resonated strongly with icon impersonators, having the actual principals
              {' '}
              <i>themselves</i>
              {' '}
participate (in some capacity) would evoke an even greater emotional bond between character/audience
            </ListGroupItemCustom>
            <ListGroupItemCustom>
              Along with attaining rough gender parity, audience demographics for BR proved multigenerational, not unlike audiences at present day Stones concerts
            </ListGroupItemCustom>
            <ListGroupItemCustom>
              Icon participation 
{' '}
<i>plus</i>
{' '}
the unorthodox biopic elements
{' '}
              <i>and</i>
              {' '}
contemporary narrative of Jubilee Blues distinguishes it from existing/upcoming music biopics
            </ListGroupItemCustom>
            <ListGroupItemCustom>
              Jubilee Blues does not obstruct a straight biopic production down the line
            </ListGroupItemCustom>
            <ListGroupItemCustom>
              The dual genre-talent/iconological draw provides financiers a hedge
            </ListGroupItemCustom>
          </ul>

        </Panel.Body>
      </Panel>
    </Row>

    <Row className="container">
      <Panel style={styles.panelStyle}>
        <Panel.Heading style={styles.panelTitle} componentClass="h3">
          REALISTIC & ATTAINABLE PROJECT
        </Panel.Heading>

        <Panel.Body style={styles.panelBody}>
          <Panel.Heading>
            <Panel.Title style={styles.listGroupTitle}>
              Probability of Stones’ participation (in some capacity) fair to
              high:
            </Panel.Title>
          </Panel.Heading>

          <ul>
            <ListGroupItemCustom>
              A compelling, unique concept
            </ListGroupItemCustom>
            <ListGroupItemCustom>
              Caricatures of principals tasteful (within a dark comedy prism);
              complementary; non-embarrassing; an overall tribute
            </ListGroupItemCustom>
            <ListGroupItemCustom>
              Aspects of the narrative/character tone tweakable
            </ListGroupItemCustom>
            <ListGroupItemCustom>
              Lack of thespian experience/advancing age taken into account:
            </ListGroupItemCustom>
            <ul>
              <ListGroupItemCustomSquare>
                <span style={{ textDecoration: 'underline' }}>
                  As principal antagonists are largely referenced
                </span>
                , individual screen time (when distributed equally among them)
                is modest: roughly 4 minutes each in Part 1 and 8 minutes each
                in Part 2
              </ListGroupItemCustomSquare>
              <ListGroupItemCustomSquare>
                As they essentially play themselves, all scenes are well within
                their capabilities; dialogue and acting coachable
              </ListGroupItemCustomSquare>
              <ListGroupItemCustomSquare>
                All scenes involving principals are non-physical
              </ListGroupItemCustomSquare>
              <ListGroupItemCustomSquare>
                Body doubles/digital face swaps an option should any scene prove
                too arduous
              </ListGroupItemCustomSquare>
            </ul>
            <ListGroupItemCustom>
              Such a unique concept involving said principals will attract top
              talent who will in turn firm principal involvement
            </ListGroupItemCustom>
          </ul>

          <Panel.Heading>
            <Panel.Title style={styles.listGroupTitle}>
              Viable project without on-screen involvement:
            </Panel.Title>
          </Panel.Heading>

          <ul>
            <ListGroupItemCustom>
              <span style={{ fontWeight: 'bold' }}>Straight impersonation</span>
              {' '}
              (built-in-audience still in play)
            </ListGroupItemCustom>
            <ListGroupItemCustom>
              <span style={{ fontWeight: 'bold' }}>Full animation</span>
              {' '}
              (built-in-audience still in play)
            </ListGroupItemCustom>
          </ul>

          <Panel.Heading>
            <Panel.Title style={styles.listGroupTitle}>
              Viable project if the Stones pass altogether:
            </Panel.Title>
          </Panel.Heading>

          <ul>
            <ListGroupItemCustom>
              Insert an alternative ‘entity’
            </ListGroupItemCustom>
            <ListGroupItemCustom>
              <span style={{ fontWeight: 'bold' }}>
                Convert into a straight crime drama/adventure
              </span>
            </ListGroupItemCustom>
          </ul>
        </Panel.Body>
      </Panel>
    </Row>

    <Row className="container">
      <Panel style={styles.panelStyle}>
        <Panel.Heading style={styles.panelTitle} componentClass="h3">
          X2
        </Panel.Heading>

        <Panel.Body>
          <Panel.Heading>
            <Panel.Title style={styles.listGroupTitle}>
              Part 1: Sunrise
            </Panel.Title>
          </Panel.Heading>
          <Panel.Heading>
            <Panel.Title style={styles.listGroupTitle}>
              Part 2: Sunset
            </Panel.Title>
          </Panel.Heading>
          <Panel.Heading>
            <Panel.Title style={styles.listGroupTitleGrey}>
              Part 3: The Concert
            </Panel.Title>
          </Panel.Heading>
        </Panel.Body>
      </Panel>
    </Row>

    <Row className="container">
      <Panel style={styles.panelStyle}>
        <Panel.Heading style={styles.panelTitle} componentClass="h3">
          All of which warrants the serious exploration of this project
        </Panel.Heading>
      </Panel>
    </Row>
  </div>
);

export default BoxOfficeTable;
