import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import {
  Grid, Row, Col, Jumbotron,
} from 'react-bootstrap';
import BannerTextured from '../components/wrappers/BannerTextured';
import SubHeading from '../components/wrappers/SubHeading';

const BannerTexturedContact = styled(Jumbotron)`
  text-align: center;
  margin-bottom: 20px;
  margin-top: 0;
  background-color: #FAFAFA;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='#C9C9C9' fill-opacity='0.150' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");

  padding-top: 54px;
  padding-bottom: 60px;

  @media (max-width: 768px) {
    padding-top: 60px;
    padding-bottom: 60px;
	}
`;

const SubHeadingContact = styled.h2`
    margin-top: 20px;
    margin-bottom: 0px;
    line-height: 1.25em;
    font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif;
    font-size: 37px;
`;

const SubHeadingContactTwo = styled.h2`
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 1.25em;
    font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif;
    font-size: 37px;
`;

const styles = {
  phone: {
    paddingTop: '20px',
  },
  email: {
    paddingTop: '0px',
    paddingBottom: '35px',
    marginBottom: '0px',
  },
  name: {
    fontSize: '24px',
    paddingTop: '15px',
  },
};

const Contact = () => (
  <div>
    <Helmet>
      <title>Contact</title>
      <meta name="description" content="" />
    </Helmet>

    {/* Page Banner */}
    <BannerTextured>
      <div className="container">
        <Row>
          <Col md={8} mdOffset={2}>
            <SubHeadingContact>
              If you would like me to write you a story,
            </SubHeadingContact>
            <SubHeadingContactTwo>
              please contact:
            </SubHeadingContactTwo>
          </Col>
        </Row>
      </div>
    </BannerTextured>

    <Grid>
      <Row className="text-center">
        <Col md={12} mdOffset={0}>
          <p style={styles.name}>Craig Henry</p>
          <p style={styles.phone}>
            <a href="tel:+84916888507" className="contact-phone1">
              +84916888507
            </a>
          </p>
          <p>
            <a href="tel:+61894091307" className="contact-phone2">
              +61894091307
            </a>
          </p>

          <p style={styles.email}>
            <a href="mailto:csh@jubileeblues.net" className="contact-email">
              csh@jubileeblues.net
            </a>
          </p>
        </Col>
      </Row>
    </Grid>

    {/* <BannerTexturedContact>
      <div className="container">
        <Row>
          <Col md={8} mdOffset={2}>
            <SubHeadingContactTwo>
              please contact:
            </SubHeadingContactTwo>
            <SubHeadingContactTwo>
              new material upon completion,
            </SubHeadingContactTwo>
            <SubHeadingContactTwo>
              Agents/managers who desire to review
            </SubHeadingContactTwo>
          </Col>
        </Row>
      </div>
    </BannerTexturedContact> */}
  </div>
);

export default Contact;
