import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import Button from '@material-ui/core/Button';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import ActionHome from '@material-ui/icons/Home';
// import ActionHome from 'material-ui/svg-icons/action/home';

import Banner from '../components/wrappers/Banner';
import SubHeading from '../components/wrappers/SubHeading';

// styling
const styles = {
  button: {
    margin: 12,
  },
  medium: {
    width: 34,
    height: 34,
    padding: 4,
  },
  label: {
    verticalAlign: 'middle',
  },
};

const NotFound = () => (
  <div>
    <Helmet title="404 | Not found" />

    <Banner>
      <Row>
        <Col md={6} mdOffset={3} sm={8} smOffset={2} xs={12}>
          <SubHeading>404 Page Not Found</SubHeading>
          <h4>
            The web page you are looking for appears to have been moved, deleted, or does not exist.
          </h4>
        </Col>
      </Row>

      <Row style={{ marginTop: 40 }}>
        <Col>
          <MuiThemeProvider>
            <Link to="/">
              <Button
                variant="contained"
                label="Take me"
                labelPosition="before"
                labelStyle={styles.label}
                primary
                icon={<ActionHome style={styles.medium} />}
                style={styles.button}
              />
            </Link>
          </MuiThemeProvider>
        </Col>
      </Row>
    </Banner>
  </div>
);

export default NotFound;
