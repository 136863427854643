import React from 'react';
import Helmet from 'react-helmet';
import { Grid, Row, Col } from 'react-bootstrap';

import BannerTextured from '../components/wrappers/BannerTextured';
import SubHeading from '../components/wrappers/SubHeading';
import BoxOfficeTable from '../components/BoxOfficeTable';

const Prospectus = () => (
  <div>
    <Helmet>
      <title>Prospectus</title>
      <meta name="description" content="" />
    </Helmet>

    {/* Page Banner */}
    <BannerTextured>
      <div className="container">
        <Row>
          <Col md={8} mdOffset={2}>
            <SubHeading>Prospectus</SubHeading>
            <p
              style={{
                textAlign: 'center',
                color: 'lightgray',
                marginTop: '20px',
                paddingBottom: '10px',
                fontSize: '23px',
              }}
            >
              At a glance
            </p>
          </Col>
        </Row>
      </div>
    </BannerTextured>

    <Grid>
      <Row>
        <BoxOfficeTable />
      </Row>
    </Grid>
  </div>
);

export default Prospectus;
