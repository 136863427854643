import styled from 'styled-components'
import { Jumbotron } from 'react-bootstrap'

const Banner = styled(Jumbotron)`
  text-align: center;
  background: #F1F1F1;
  margin-bottom: 0;
  font-size: 23px;

  padding-top: 60px;
  padding-bottom: 30px;

`

export default Banner
