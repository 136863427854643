import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import './css/index.css';

import { Navbar, Nav, NavItem } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Amplify, { Auth } from 'aws-amplify';
import Footer from './components/Footer';

import Routes from './Routes';
import awsConfig from './aws-exports';
// import awsConfig from './awsConfig';

Amplify.configure(awsConfig);

const title = 'Jubilee Blues';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      expanded: false,
    };
  }

  async componentDidMount() {
    try {
      if (await Auth.currentSession()) {
        this.userHasAuthenticated(true);
      }
    } catch (e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    this.setState({
      isAuthenticating: false,
      expanded: false,
    });
  }

  userHasAuthenticated = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  handleLogout = async (event) => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
    this.props.history.push('/');
  };

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
    };
    return (
      !this.state.isAuthenticating && (
        <Fragment>
          <div className="Site">
            <div className="Site-content">
              <div className="no-user-select">
                <Helmet titleTemplate={`%s - ${title}`} />

                <Navbar fixedTop inverse collapseOnSelect>
                  <Navbar.Header>
                    <Navbar.Brand>
                      <Link to="/" className="">
                        <span className="" />
                      </Link>
                      <Link to="/">
                        <span style={{ fontWeight: 'bold' }}>JB</span>
                      </Link>
                    </Navbar.Brand>

                    <Navbar.Toggle />
                  </Navbar.Header>

                  {this.state.isAuthenticated ? (
                    <Navbar.Collapse>
                      <Nav pullRight>
                        <LinkContainer to="/genesis-preamble">
                          <NavItem>GENESIS & PREAMBLE</NavItem>
                        </LinkContainer>
                        <LinkContainer to="/part-1-synopsis">
                          <NavItem>PART 1 SYNOPSIS</NavItem>
                        </LinkContainer>
                        <LinkContainer to="/part-2-synopsis">
                          <NavItem>PART 2 SYNOPSIS</NavItem>
                        </LinkContainer>
                        <LinkContainer to="/double-entendres">
                          <NavItem>DOUBLE ENTENDRES</NavItem>
                        </LinkContainer>
                        <LinkContainer to="/prospectus">
                          <NavItem>PROSPECTUS</NavItem>
                        </LinkContainer>
                        <LinkContainer to="/contact">
                          <NavItem>CONTACT</NavItem>
                        </LinkContainer>
                        <NavItem onClick={this.handleLogout}>LOGOUT</NavItem>
                      </Nav>
                    </Navbar.Collapse>
                  ) : (
                    <Navbar.Collapse>
                      <Nav pullRight>
                        <LinkContainer to="/contact">
                          <NavItem>CONTACT</NavItem>
                        </LinkContainer>
                        <LinkContainer to="/login">
                          <NavItem>LOGIN</NavItem>
                        </LinkContainer>
                      </Nav>
                    </Navbar.Collapse>
                  )}
                </Navbar>

                <Routes childProps={childProps} />
              </div>
            </div>
            <Footer />
          </div>
        </Fragment>
      )
    );
  }
}

export default withRouter(App);
