import React from 'react';
import { Table } from 'react-bootstrap';

const styles = {
  trWhite: {
    background: 'white',
    textAlign: 'center',
    fontSize: '25px',
    lineHeight: '2.25em',
    fontWeight: 'bold',
    // fontFamily: "Verdana, Verdana, Geneva, sans-serif"
    fontFamily: 'Arial, Arial, Helvetica, sans-serif',
  },
  thWhite: {
    background: 'white',
    textAlign: 'center',
    fontSize: '23px',
    lineHeight: '1.25em',
    fontWeight: 'bold',
    verticalAlign: 'middle',
    fontFamily: 'Arial, Arial, Helvetica, sans-serif',
  },
  thColour: {
    background: '#D2D3DB',
    textAlign: 'center',
    fontSize: '23px',
    lineHeight: '1.25em',
    fontWeight: 'bold',
    verticalAlign: 'middle',
    fontFamily: 'Arial, Arial, Helvetica, sans-serif',
  },
  tdWhite: {
    background: 'white',
    textAlign: 'center',
    fontSize: '23px',
    lineHeight: '1.55em',
    paddingTop: '30px',
    paddingBottom: '30px',
    paddingLeft: '20px',
    paddingRight: '20px',
    verticalAlign: 'middle',
    fontFamily: 'Arial, Arial, Helvetica, sans-serif',
  },
  tdColour: {
    background: '#D2D3DB',
    textAlign: 'center',
    fontSize: '23px',
    lineHeight: '1.5em',
    paddingTop: '30px',
    paddingBottom: '30px',
    paddingLeft: '20px',
    paddingRight: '20px',
    verticalAlign: 'middle',
    fontFamily: 'Arial, Arial, Helvetica, sans-serif',
  },
};

const DoubleEntendresTable = () => (
  <div className="container" style={{ textAlign: 'center' }}>
    {/* <Row className="container"> */}

    <Table bordered hover>
      <thead>
        <tr>
          <th colSpan="2" style={styles.trWhite}>
            PART 1: SUNRISE
          </th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th className="col-md-6 col-sm-6 col-xs-6" style={styles.thWhite}>
            FICTIONAL GANGSTER NARRATIVE
          </th>
          <th className="col-md-6 col-sm-6 col-xs-6" style={styles.thColour}>
            REALITY
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={styles.tdWhite}>
            The Crawdaddy Crew (the Outfit) is the most feared and powerful crime cartel on the
            planet.
          </td>
          <td style={styles.tdColour}>
            The Rolling Stones are commonly referred to as the greatest rock ‘n’ roll band on the
            planet.
          </td>
        </tr>
        <tr>
          <td style={styles.tdColour}>Bill is well known for his stoic stage persona.</td>
          <td style={styles.tdWhite}>His moniker ‘Wild Bill’ is an ironic play on this.</td>
        </tr>
        <tr>
          <td style={styles.tdColour}>
            Mick is well known for his financial acumen. He manages business operations for the
            band.
          </td>
          <td style={styles.tdWhite}>
            His moniker ‘Mick Money’ is a play on this. He administers the Outfit’s finances.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            During a conversation with Mick, Ronnie quips “so much for quiet retirement” when told
            about Bill’s arrest for murder one in Sofia, Bulgaria.
          </td>
          <td style={styles.tdColour}>Bill retired from the Stones in 1992.</td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            Charlie owns a jazz club-cum-office called The Ode (to a High Flying Bird) in East
            London. A logo of a bird in an egg playing the saxophone adorns the establishment’s
            marquee.
          </td>
          <td style={styles.tdColour}>
            Charlie, a jazz enthusiast, wrote a book called ‘Ode to a High Flying Bird’ in honor of
            Charlie Parker. It has an illustration of a bird in an egg playing a saxophone on its
            cover.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            After suffering unsolicited advances from a gay gent in what Pete thinks is a
            heterosexual strip club, he feigns interest in order to get the perpetrator to the
            toilets where he borrows a guitar off a patron at the urinal and uses it to smash the
            shit out of the gent, completely destroying the guitar.
            <span className="custom-button">
              <a
                href="https://drive.google.com/file/d/1CbLE9s5Z2qlOjvD2C7KR-NVposXa0Tqa/view?usp=…"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="script-entendre-button">View Script Excerpt</span>
              </a>
            </span>
          </td>
          <td style={styles.tdColour}>
            Pete is renowned for smashing Rickenbackers during performances in the 60’s.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            Bill is arrested for murder one in Sofia, Bulgaria and awaits trial in Sofia Central
            Prison.
          </td>
          <td style={styles.tdColour}>
            A loose analogy of Bill’s retirement, thus separation from the band.
          </td>
        </tr>
        <tr>
          <td colSpan="2" style={styles.tdWhite}>
            The following bullet points relate to an investigative scene where, via a combination of
            authentic, doctored and fabricated pics coupled with verbal explanation, Debreto schools
            Johnny on the history and present status of the Outfit. It is referenced that:
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            • Brian Jones was a founding member of the Outfit, was fired from the board in ‘68, and
            died in mysterious circumstances not long after.
          </td>
          <td style={styles.tdColour}>
            • Brian Jones was a founding member of the Stones, was fired from the band in ’68, and
            died shortly thereafter in ’69. Conspiracy theories have enveloped his passing.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            • The Kray Brothers were the kings of the London underworld prior to the emergence of
            and supplantation by the Outfit. Their arrests and subsequent imprisonment are also
            alluded to.
          </td>
          <td style={styles.tdColour}>
            • The Kray Brothers ruled the London underworld from the late 50’s up until their arrest
            in ‘68 and subsequent imprisonment in ‘69.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            • The Outfit acquired their official name ‘The Crawdaddy Crew’ from a club they
            frequented socially as up and coming gangsters in the early 60’s called the Crawdaddy
            Club located in Richmond, London.
          </td>
          <td style={styles.tdColour}>
            • The Stones played their first residency at the Crawdaddy Club in Richmond, London
            1963.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            • Mick attended the London School of Economics prior to joining the Outfit, hence his
            financial acumen and role as the Outfit’s moneyman.
          </td>
          <td style={styles.tdColour}>
            • Mick briefly attended the London School of Economics during the early years of the
            Stones.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>• The Outfit was founded in 1962.</td>
          <td style={styles.tdColour}>• The Stones played their first gig in 1962.</td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            • Mick Taylor was promoted to the board after Brian’s departure.
          </td>
          <td style={styles.tdColour}>• Mick Taylor joined the Stones after Brian’s departure.</td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            • The British establishment forced the Outfit into exile in ’71 via heavy tax penalties.
          </td>
          <td style={styles.tdColour}>
            • The Stones were exiled from the UK in ’71 due to tax issues.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>• They regrouped at Villa Nellcote on the French Riviera.</td>
          <td style={styles.tdColour}>
            • They relocated to the French Riviera where they recorded Exile on Main St. at Villa
            Nellcote.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            • Mick and Keith were arrested for drug trafficking in ’67, but charges were dropped due
            to an implied lack of evidence and/or a technicality.
          </td>
          <td style={styles.tdColour}>
            • Mick and Keith were arrested for drug possession in ’67, but their convictions were
            overturned.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            • Ronnie was elevated to the board in ‘75 at the expense of Taylor “due to his wire
            tapping expertise.”
          </td>
          <td style={styles.tdColour}>
            • Ron Wood joined the Stones in ’75 after Mick Taylor’s departure.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            • Their exile gave impetus to the formation of a truly international empire, reaching
            the four corners of the globe.
          </td>
          <td style={styles.tdColour}>
            • A loose analogy to the period when the Stones released some of their most revered
            albums and started their mammoth global stadium tours.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            • It is rumored that there was a 6th Don, a secretive founding member who always
            remained in the shadows, but nobody knows for sure.
            <span className="custom-button">
              <a
                href="https://drive.google.com/file/d/1wm46SzkhH0oAvtP27uctmI8iE6cx0G5X/view?usp=…"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="script-entendre-button">View Script Excerpt</span>
              </a>
            </span>
          </td>
          <td style={styles.tdColour}>• Ian Stewart was the 6th Stone and a founding member.</td>
        </tr>
        <tr>
          <td style={styles.tdColour}>
            With his missing teeth and sunken eyes (in the 70’s), skull ring and fashion style,
            Keith has a voodoo pirate look. Moreover, he named his cat Voodoo, and dubbed his
            terrace Voodoo’s lounge, which in turn gave name to the Stones’ ’94 album Voodoo Lounge.
          </td>
          <td style={styles.tdWhite}>
            Keith’s moniker ‘Voodoo Richards’ is a play on this. He also dabbles in voodoo; holds
            voodoo ceremonies at his jungle lair.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            Bill boards a long-tail boat on the Mae Ping River in Chiang Mai and speeds off toward a
            setting sun on the horizon.
          </td>
          <td style={styles.tdColour}>
            Alludes to the sun setting on his/their careers and lives.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            When Bill, post facial surgery and thus unrecognizable, approaches his ride outside of
            the Heathrow arrivals terminal, a goon asks the coded question “Who’s your best friend?”
            To which he replies “Nanker Phelge”, which in turn grants him entry into the vehicle.
          </td>
          <td style={styles.tdColour}>
            ‘Nanker Phelge’ was a collective pseudonym used between ‘63 and ’65 for Rolling Stones
            group compositions.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            Bill returns home to the UK and back into the fold of the Outfit after a long absence.
          </td>
          <td style={styles.tdColour}>
            A loose analogy of when Bill rejoined the Stones on stage for the first time in twenty
            years at their 50th anniversary shows at the 02 Arena.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            After arriving in Caracas and checking into her hotel room, Babes notices the disturbing
            ‘Turner Purple Orchestra’ poster on the wall above her bed.
          </td>
          <td style={styles.tdColour}>
            The ‘Turner Purple Orchestra’ poster is mise en scene from the film ‘Performance’ in
            which Mick stars and foreshadows a scene in Part 2.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            Babes kills time by watching a Buddy Holly documentary on TV, which surreptitiously
            forecasts her demise via a plane crash in Part 2.
          </td>
          <td style={styles.tdColour}>Buddy Holly perished in a plane crash in 1959.</td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            Charlie conducts business with an Italian gangster in a stable at his Arabian horse stud
            farm in Devon, England.
          </td>
          <td style={styles.tdColour}>
            Charlie and Shirley Watts breed Arabian horses at their stud farm in Devon, England.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            While conversing with the Italian gangster, Charlie sarcastically quips “Now Giuseppe, I
            better not wake up with (nods at the mare in the stall next to him) Missy’s head in me
            bed tomorrow morning or we’ll be re-opening the Colosseum by
            {' '}
            {"week's"}
            {' '}
end if you
            know what I mean.”
          </td>
          <td style={styles.tdColour}>
            Homage to the infamous ‘horse head in the bed’ scene in The Godfather.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            Bill reunites with Charlie, his old pal and fellow Don, after a long separation at the
            latter’s estate in Devon.
          </td>
          <td style={styles.tdColour}>
            Bill reunited with Charlie, his old pal and partner in the Stones rhythm section, on
            stage for the first time in twenty years at the 50th anniversary shows.
          </td>
        </tr>
        <tr>
          <td colSpan="2" style={styles.trWhite}>
            PART 2: SUNSET
          </td>
        </tr>
        <tr>
          <td colSpan="2" style={styles.tdWhite}>
            The following bullet points relate to a scene where Curtis and Mick watch TV in the back
            of their limo while awaiting a confirmation text message.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            • Curtis flicks channels and pauses on the film ‘Performance’ and asks Mick “Do you
            remember seeing this back in the day? To which Mick replies “This does look vaguely
            familiar.” They continue watching.
          </td>
          <td style={styles.tdColour}>• Mick stars in the 1970 film.</td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            • While Mick and Curtis watch the ‘Hashishin’ scene on TV, the two thugs up front exit
            the limo and assassinate Andy.
          </td>
          <td style={styles.tdColour}>
            • During the ‘Hashishin’ scene, Mick’s character Mr Turner reads the story of the Old
            Man of the Mountain, the leader of the Nizari Ismaili Hashishins (assassins).
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            • During the ‘Memo from Turner’ scene where Mr Turner transforms into Gangster Turner, a
            quick succession of shots juxtapose Mick’s visage to that of the transformation
            happening on screen.
          </td>
          <td style={styles.tdColour}>
            • Mick’s character Mr Turner transforms into Gangster Turner in ‘Performance’ while Mick
            plays a gangster in ‘Jubilee Blues’.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            • When Mick looks out the limo window as it departs, we see a brief amalgam of
            Mick/Gangster Turner.
            <span className="custom-button">
              <a
                href="https://drive.google.com/file/d/1x3eo2M_5h-OJxKT-ZSwA_VFzxDNMMCJd/view?usp=…"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="script-entendre-button">View Script Excerpt</span>
              </a>
            </span>
          </td>
          <td style={styles.tdColour}>
            • During the final scene of the film when the character Chas looks out the window of the
            Rolls Royce as it departs, we briefly see Mr Turner’s visage.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            While fishing in Amazonas, Keith loses balance as he slides off the tree stump he is
            perched on and lands arse up on the jungle floor, unhurt.
          </td>
          <td style={styles.tdColour}>
            While holidaying in Fiji in 2006, Keith lost balance as he climbed down from the tree
            branch he was perched on and subsequently hit his head on the trunk, fracturing his
            skull.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            When the chief mate informs Keith that they are ready to weigh anchor, Keith responds,
            “Well what are you waiting for. (Gives the head, heart and balls salute) Anchor
            aweighhhh.”
          </td>
          <td style={styles.tdColour}>
            Keith occasionally utilizes the ‘head, heart and balls’ salute, representing the flow of
            energy, to express acknowledgment or gratitude.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            While kneeling over the toilet bowl seasick, Mick, in between heaves, yells “Keith you
            fucking bastard!” in reference to Keith’s preference for sailing and not flying (part of
            the way) to their destination.
          </td>
          <td style={styles.tdColour}>
            A light play on the past/present acrimony existing between Mick and Keith.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            During calm seas, Mick, at the bowsprit, and Keith, sternward, gaze out to sea and
            reflect.
          </td>
          <td style={styles.tdColour}>
            Depicts reflection upon their own eventful careers/lives and while the contrasting
            positions on the ship allude to past differences, calm seas represent reconciliation;
            yet regardless of personal circumstance, both are inextricably wed to the same vessel
            (the Stones).
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            Behind the drum kit, a contented Charlie leads his band through a jazz number during a
            rehearsal at The Ode.
          </td>
          <td style={styles.tdColour}>Charlie’s true musical love is jazz.</td>
        </tr>
        <tr>
          <td colSpan="2" style={styles.tdWhite}>
            The following bullet points relate to a scene where a BBC news broadcast references, via
            a combination of authentic, doctored and fabricated pics, the Outfit’s upcoming golden
            jubilee.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            • After discussing Queen Elizabeth II’s upcoming diamond jubilee, a news anchor mentions
            “another anniversary rumored to be taking place a little later this month . . . the
            founding of the Crawdaddy Crime Cartel . . .”
          </td>
          <td style={styles.tdColour}>
            • Queen Elizabeth II’s diamond jubilee took place in 2012, the same year the Stones
            celebrated their golden jubilee.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            • The studio cuts to a reporter standing outside the location of what once was the
            Station Hotel in Richmond, London circa 1962-63, who in turn explains (i) how the
            Crawdaddy Club was located in a back room of the hotel; (ii) how the Outfit used to
            socialize in the club and thus took their name from it; (iii) how it was closed down in
            ’63 due to a riot instigated by the Dons.
          </td>
          <td style={styles.tdColour}>
            • It is the same site of the Station Hotel, Richmond, London circa 1962-63; (i) the
            Crawdaddy Club was located in the back room of the hotel; (ii) the Stones played their
            first residency there in ‘63; (iii) the club closed its doors and relocated to nearby
            Richmond Athletic Club later the same year due to overcrowding and rowdiness while
            Stones’ gigs were riotous in those days.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            When Mick claims ignorance as to which side starboard is prior to sweeping the ship for
            intruders, Keith retorts “The right bloody side.” Unhappy with his tone, Mick replies
            “OK, keep ya bloody britches on.” And then goes on to quiz his knowledge of business:
            “Alright genius. What’s an IPO?” (off Keith’s blank expression) “Don’t know ha? . . .”
            After another blank, Keith, with resignation replies “OK, OK, point taken. Now can we
            please get on with it?”
          </td>
          <td style={styles.tdColour}>
            A comical play on their past friction, and two old friends getting on each other’s
            nerves.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            Mick and Keith almost accidently shoot each other while sweeping their respective sides
            of the ship for intruders.
          </td>
          <td style={styles.tdColour}>A further play on their past friction.</td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            With the ship secure, Mick sidles up to Keith by the rail and quips “Fuck me Keith, to
            be honest, I haven't had this much fun since the 60’s.” To which Keith replies “Yup, the
            good ol’ days.”
          </td>
          <td style={styles.tdColour}>
            A reference to when it all started; their newly found success and fame; the craziness of
            the early 60’s concerts; the swinging 60’s etcetera.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            After their fishing endeavors, Mick and Keith kick back with drinks and cigars while
            watching a majestic sunset.
          </td>
          <td style={styles.tdColour}>
            Homage to their comradeship and partnership; alludes to the sun setting on their
            careers/lives.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            When Mojo slips overboard, Keith wishes him luck and leaves him to his fate as it’s full
            steam ahead to London and their golden jubilee.
          </td>
          <td style={styles.tdColour}>
            A play on ‘the show must go on’ rock mantra of which the Stones subscribe to while on
            the road; whether it be arrests, weddings or funerals, nothing gets in the way of the
            next gig.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            As Alan Dunn, The Ode’s manager, peruses the gig guide in the paper, we first clock the
            date of the paper (July 11th, 2012) before zeroing in on an ad, hidden amongst present
            day ads, promoting The Rolling Stones’ first ever gig at the Marquee Club on July 12th,
            1962.
          </td>
          <td style={styles.tdColour}>
            Alan Dunn has been the Stones’ tour manager since ‘68 while the ad is the original
            Marquee ad of Thursday July 12th, 1962, which announced the Stones’ first gig.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            Keith exits his cabin, holds his arms out expectantly and says “put my fucking robe on
            right” to Pablo, the chief mate, who dutifully helps him into his coat.
          </td>
          <td style={styles.tdColour}>
            As an assistant drapes a robe over his shoulders after a show, Keith has been known to
            say “Put my robe on right! Put on my robe!”, a line borrowed from Scorsese’s Raging
            Bull.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            As Charlie’s Roller passes 90 Wardour Street, we glimpse the original facade of the
            Marquee Club circa 1971.
          </td>
          <td style={styles.tdColour}>
            The Stones returned to the Marquee Club for a one off gig at its 90 Wardour Street
            location in 1971.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            As Charlie’s Roller passes 165 Oxford Street, we see the original facade of the Marquee
            Club circa 1962.
          </td>
          <td style={styles.tdColour}>
            165 Oxford Street was the original site of the Marquee Club where the Stones played
            their first gig in 1962.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            Charlie’s Roller pulls up outside of a nondescript restaurant not far from Wembley
            Stadium called Beggar’s Banquet.
          </td>
          <td style={styles.tdColour}>
            Beggar’s Banquet is the name of the Stones’ seminal ‘68 album.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            Behind a heavy gate adjacent to Beggars Banquet is Heathen Way, a pathway that leads to
            an antiquated pub called the Heathen Inn.
            <span className="custom-button">
              <a
                href="https://drive.google.com/file/d/1OoXRaowf62j_rMCvWAmV3nB3ppOgaDQU/view?usp=…"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="script-entendre-button">View Script Excerpt</span>
              </a>
            </span>
          </td>
          <td style={styles.tdColour}>
            Heathen Way is a play on Wembley Way, the famed pedestrian road that links the Wembley
            Park tube station to Wembley Stadium of which the Stones have played numerous times
            while the ‘Heathen’ in Heathen Way and Heathen Inn corresponds to nearby Harrow on the
            Hill, the site of an ancient heathen temple.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            Johnny Starbuck, the Inn’s sergeant-of-arms, guides Bill, Ronnie and Charlie along
            Heathen Way to the Inn.
          </td>
          <td style={styles.tdColour}>
            Johnny Starbuck is Ronnie and Keith’s long time guitar tech while on tour.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            The Blue Lena awaits Mick and Keith at the wharf prior to whisking them away to the
            Heathen Inn.
          </td>
          <td style={styles.tdColour}>
            The Blue Lena, a 1965 Bentley S3 Continental Flying Spur Sports Saloon, was Keith’s ride
            for decades.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            For the first time in a long time all five Dons reunite in merriment in the inner
            sanctum.
          </td>
          <td style={styles.tdColour}>
            Alludes to their 50th anniversary shows whereby the quartet hadn’t played together for
            five years and Bill hadn’t played with the band for 20 years.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            A portrait of Ian Stewart, the secretive 6th Don, adorns the wall of the inner sanctum.
          </td>
          <td style={styles.tdColour}>Ian Stewart was the 6th Stone and a founding member.</td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            The Outfit was founded on July 12th, 1962, and they celebrate their golden jubilee at
            the stroke of midnight on July 12th, 2012.
          </td>
          <td style={styles.tdColour}>
            The Stones played their first gig at the Marquee Club on July 12th, 1962, and they
            celebrated their golden jubilee in 2012.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            Charlie gets into Johnny’s face and says “Your Ma and Pa, sonny boy, made me leave my
            beloved London for froggy land. And I bloody well don’t like froggy land!”
          </td>
          <td style={styles.tdColour}>Alludes to his/the Stones’ exile to France in ‘71.</td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            After Mick opens a story with “There were these five guys”, the song ‘Time On My Side’
            commences over a frozen frame of the contented Dons sitting around a table.
          </td>
          <td style={styles.tdColour}>
            Alludes to the Stones’ longevity, and was one of their early hits.
          </td>
        </tr>
        <tr>
          <td style={styles.tdWhite}>
            Backstage at Wembley, curtains part, revealing the remnant streaks of a set sun on the
            horizon.
          </td>
          <td style={styles.tdColour}>Alludes to the sun setting on their careers/lives.</td>
        </tr>
      </tbody>
    </Table>

    {/* </Row> */}
  </div>
);

export default DoubleEntendresTable;
