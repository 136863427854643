import React from "react";
import { Switch, Route } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import Home from "./views/Home";
import Login from "./containers/Login";
import GenesisPreamble from "./views/GenesisPreamble";
import P1Synopsis from "./views/P1Synopsis";
import P2Synopsis from "./views/P2Synopsis";
import DoubleEntendres from "./views/DoubleEntendres";
import Prospectus from "./views/Prospectus";
import Contact from "./views/Contact";
import NotFound from "./views/NotFound";

// The Routes component renders one of the specified Routes
// will place the HTML generated by a matched route inside of a <main> DOM node
// note: remember "exact path" and "component" below are PROPS. They. are. Props.

const Routes = ({ childProps }) => (
  <main>
    <Switch>
      <AppliedRoute path="/" exact component={Home} props={childProps} />
      <UnauthenticatedRoute
        path="/login"
        exact
        component={Login}
        props={childProps}
      />
      <AuthenticatedRoute
        exact
        path="/genesis-preamble"
        component={GenesisPreamble}
        props={childProps}
      />
      <AuthenticatedRoute
        exact
        path="/part-1-synopsis"
        component={P1Synopsis}
        props={childProps}
      />
      <AuthenticatedRoute
        exact
        path="/part-2-synopsis"
        component={P2Synopsis}
        props={childProps}
      />
      <AuthenticatedRoute
        exact
        path="/double-entendres"
        component={DoubleEntendres}
        props={childProps}
      />
      <AuthenticatedRoute
        exact
        path="/prospectus"
        component={Prospectus}
        props={childProps}
      />
      <Route exact path="/contact" component={Contact} />
      <Route component={NotFound} />
    </Switch>
  </main>
);

export default Routes;
