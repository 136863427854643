import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
// Put any other imports below so that CSS from your
// components takes precedence over default styles.

import React from 'react';
import { render } from 'react-snapshot';
import { BrowserRouter } from 'react-router-dom';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import ScrollToTop from './components/ScrollToTop';
import Theme from './theme';
import App from './App';
import { unregister } from './registerServiceWorker';
import './css/index.css';

const rootEl = document.getElementById('root');

render(
  <BrowserRouter>
    <ScrollToTop>
      <MuiThemeProvider theme={Theme}>
        <CssBaseline />
        <App />
      </MuiThemeProvider>
    </ScrollToTop>
  </BrowserRouter>,

  rootEl,
);

unregister();
