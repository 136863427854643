import React from 'react';
import Divider from '@material-ui/core/Divider';
import { Grid, Row, Col } from 'react-bootstrap';

// styling
const styles = {
  gridstyle: {
    background: '#2F2F2F',
    marginTop: '40px',
    width: '100%',
  },
  copyright: {
    marginTop: 20,
    marginBottom: 20,
    textAlign: 'center',
    color: 'white',
    fontSize: 15,
  },
};

const Footer = () => (
  <Grid fluid style={styles.gridstyle}>
    <Divider />

    <div className="no-user-select">
      <Row>
        <Col md={8} mdOffset={2}>
          <p className="footer" style={styles.copyright}>
            All works herein are registered with the US Copyright Office. All Rights Reserved.
          </p>
        </Col>
      </Row>
    </div>
  </Grid>
);

export default Footer;
