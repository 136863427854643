import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Image } from 'cloudinary-react';
import { Carousel } from 'react-bootstrap';

const MyCarousel = styled(Carousel)`
  margin: 60px 40px 60px 34px;
  width: 1050px;
  max-height: auto;
`;

const MyDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

class StoryBoardsCarousel extends Component {
  state = {
    storyboards: [],
  };

  componentDidMount() {
    // Request for images tagged synopsis1
    axios.get('https://res.cloudinary.com/jubilee-blues/image/list/synopsis1.json').then((res) => {
      // console.log(res.data.resources);
      this.setState({ storyboards: res.data.resources });
    });
  }

  render() {
    return (
      <MyDiv>
        <MyCarousel
          // style={{ margin: "60px", width: "1050px", maxHeight: "auto" }}
          indicators
          interval="3000"
        >
          {this.state.storyboards.map(storyboard => (
            <Carousel.Item key={storyboard.public_id}>
              <Image cloudName="jubilee-blues" publicId={storyboard.public_id} />
            </Carousel.Item>
          ))}
        </MyCarousel>
      </MyDiv>
    );
  }
}

export default StoryBoardsCarousel;
