import styled from 'styled-components'

const SubHeading = styled.h2`

    margin-top: 20px;
    margin-bottom: 0px;
    line-height: 1.25em;
    font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif;

`

export default SubHeading
