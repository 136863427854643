import styled from 'styled-components'
import { Jumbotron } from 'react-bootstrap'

const BannerTextured = styled(Jumbotron)`
  text-align: center;
  margin-bottom: 20px;
  margin-top: 0;
  background-color: #FAFAFA;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='#C9C9C9' fill-opacity='0.150' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");

  padding-top: 60px;
  padding-bottom: 60px;

  @media (max-width: 768px) {
    padding-top: 60px;
    padding-bottom: 60px;
	}
`

export default BannerTextured
