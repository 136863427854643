import React from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-bootstrap';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Banner from './wrappers/Banner';
import TagLine from './wrappers/TagLine';

// styles
const styles = {
  text: {
    textAlign: 'center',
    fontFamily: 'Arial, Arial, Helvetica, sans-serif',
    color: '#2f2f2f',
  },
  heading: {
    paddingTop: '20px',
    paddingBottom: '20px',
    fontFamily: 'Arial, Arial, Helvetica, sans-serif',
  },
  cardContainer: {
    borderRadius: '0px',
  },
  title: {
    fontSize: '4em',
    lineHeight: '1em',
    fontWeight: 'normal',
    marginTop: '10px',
    marginBottom: '10px',
    fontFamily: 'Arial, Arial, Helvetica, sans-serif',
  },
  cardColLeft: {
    paddingRight: '0px',
  },
  cardColRight: {
    paddingLeft: '0px',
  },
  bold: {
    fontWeight: '900',
  },
};

const CardLink = styled(Link)`
  &:hover {
    text-decoration: none;
    font-family: Arial, Arial, Helvetica, sans-serif;
  }
`;

const Text = styled(CardContent)`
  font-family: Arial, Arial, Helvetica, sans-serif;
  margin: 0 10px;
`;

const HomeAuthenticated = () => (
  <div>
    <Helmet>
      <title>Home</title>
      <meta name="description" content="" />
    </Helmet>

    <Banner>
      <p className="home-title">JUBILEE BLUES</p>
      <TagLine>An Epic Two-Part Feature Film</TagLine>
      <h3>For Your Consideration</h3>
    </Banner>

    {/* <MuiThemeProvider> */}
    <Grid>
      <Row>
        <Col md={6} mdOffset={0} className="homecard-column">
          <CardLink to="/part-1-synopsis">
            <Card style={styles.cardContainer} className="homecard">
              <div>
                <Text style={styles.text}>
                  <div className="homecard-heading" style={styles.heading}>
                    <h4 style={{ fontWeight: '900' }}>Part 1: Sunrise</h4>
                    <p>"American Gangster meets Apocalypse Now"</p>
                  </div>
                  {/* <h4 style={{fontWeight:'bold'}}>GENRE</h4> */}
                  <p style={{ paddingBottom: '20px' }}>
                    Crime drama/adventure; (sub-genre) dark comedy
                  </p>
                  {/* <h4 style={{fontWeight:'bold'}}>LOGLINE</h4> */}
                  <div className="homecard-logline">
                    <p style={{ paddingBottom: '20px', textAlign: 'left' }}>
                      The son of a murdered husband and wife investigative journo team allies with
                      an aggrieved Vietnam vet to seek revenge on a voodoo dabbling crime cartel
                      that has
                      {' '}
                      <span style={{ fontWeight: '900' }}>Sympathy for the Devil.</span>
                    </p>
                  </div>

                  {/* <h4 style={{fontWeight:'bold'}}>SET</h4> */}
                  <div className="homecard-set">
                    <p style={{ paddingBottom: '20px' }}>
                      Set between December, 2011 and June, 2012
                    </p>
                  </div>
                </Text>
              </div>
            </Card>
          </CardLink>
        </Col>

        <Col md={6} className="homecard-column">
          <CardLink to="/part-2-synopsis">
            <Card style={styles.cardContainer} className="homecard">
              <div>
                <Text style={styles.text}>
                  <div className="homecard-heading" style={styles.heading}>
                    <h4 style={{ fontWeight: '900' }}>Part 2: Sunset</h4>

                    <p>“Captain Phillips meets Snatch”</p>
                  </div>
                  {/* <h4 style={{fontWeight:'bold'}}>GENRE</h4> */}
                  <p style={{ paddingBottom: '20px' }}>
                    Crime drama/adventure; (sub-genre) dark comedy
                  </p>
                  {/* <h4 style={{fontWeight:'bold'}}>LOGLINE</h4> */}
                  <div className="homecard-logline">
                    <p style={{ paddingBottom: '52px', textAlign: 'left' }}>
                      The aging Dons journey home to London to celebrate their golden jubilee all
                      the while dodging the law and two ghosts from the past hell-bent on
                      retribution.
                    </p>
                  </div>

                  {/* <span style={{paddingBottom: '20px'}}></span> */}
                  {/* <h4 style={{fontWeight:'bold'}}>SET</h4> */}
                  <div className="homecard-set">
                    <p style={{ paddingBottom: '20px' }}>Set in June and July, 2012</p>
                  </div>
                </Text>
              </div>
            </Card>
          </CardLink>
        </Col>
      </Row>
    </Grid>
    {/* </MuiThemeProvider> */}
  </div>
);

export default HomeAuthenticated;
