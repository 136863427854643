import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import BannerTextured from './wrappers/BannerTextured';
import SubHeading from './wrappers/SubHeading';

const styles = {
  label: {
    color: '#fff',
    textTransform: 'none',
    fontSize: '18px',
    fontWeight: '500',
    letterSpacing: '.5px',
    fontFamily: 'Tahoma, Geneva, sans-serif',
    margin: 12,
    width: 140,
    borderRadius: '4px',
    textDecoration: 'none',
  },
  epigraph: {
    paddingTop: '20px',
    paddingBottom: '20px',
    textAlign: 'left',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: '40px 40px 200px 40px',
  },
};
const HomeUnAuthenticated = () => (
  <Fragment>
    <BannerTextured>
      <div className="container">
        <Row>
          <Col md={12} mdOffset={0}>
            <SubHeading>Jubilee Blues Synopses</SubHeading>
            <h4 style={styles.epigraph}>
              Raise the chalice to thy lips, respire deeply, and then swallow the potion – every
              last drop! Now brace thyself, for ye may feel some initial discomfort, but hold fast,
              for it will soon subside, and ye with faith will be rewarded with rivers of solid
              gold.
            </h4>
          </Col>
        </Row>
        <Row>
          <Col md={12} mdOffset={0}>
            <p
              style={{
                textAlign: 'right',
                fontWeight: 'bolder',
                margin: '0px',
                padding: '0px 50px 0px 0px',
                fontSize: '23px',
              }}
            >
              <i>-- J. Blues</i>
            </p>
          </Col>
        </Row>
      </div>
    </BannerTextured>

    <div style={styles.buttonContainer}>
      <Link to="/login">
        <div id="login">
          <span className="custom-login-button">
            <span className="login-button">LOGIN</span>
          </span>
        </div>
      </Link>

      <Link to="/contact">
        <div id="contact">
          <span className="custom-contact-button">
            <span className="contact-button">CONTACT</span>
          </span>
        </div>
      </Link>
    </div>
  </Fragment>
);

export default HomeUnAuthenticated;
